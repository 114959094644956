import axios from "@standard/axios";

export const resetPassword = (id) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(`master/user/${id}/resetpassword`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
export const unlocked = (id) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .put(`master/user/${id}/unlocked`)
      .then((res) => resolve(res.data))
      .catch((errors) => reject(errors));
  });

  return promise;
};
