import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AlertService, AuthService } from "@standard/services";
import { BoxContainerUnauthen } from "@standard/components/BoxContainer";
import { useEffect, useState } from "react";
import _ from "lodash";

const defaultValues = {
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = ({ history }) => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const { register, watch, formState, handleSubmit } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    var params = {
      token,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    console.log(`params`, params);

    AuthService.changePasswordFromResetPassword(params)
      .then(() => {
        setIsLoading(false);
        AlertService.done("Your password was changed").then(() =>
          history.push("/login")
        );
      })
      .catch((error) => {
        setIsLoading(false);
        setErrors(error);
      });
  };

  const _errors = [];
  for (var i in formState.errors) _errors.push(formState.errors[i].message);

  return (
    <BoxContainerUnauthen
      isLoading={isLoading}
      errors={_errors.concat(errors)}
      title="Change Password"
      description="Enter your new password and confirm password"
      onClearError={() => setErrors([])}
    >
      <form className="m-t" id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            {...register("newPassword", {
              required: "Please enter your new password.",
              validate: (val) => {
                if (val !== watch("confirmPassword"))
                  return "Confirm Password must be equal to New Password.";

                return true;
              },
            })}
            name="newPassword"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            {...register("confirmPassword", {
              required: "Please enter your confirm password.",
            })}
            name="confirmPassword"
          />
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary block full-width m-b "
          >
            Change Password
          </button>
        </div>
        <div className="form-group float-right">
          <Link to="/login">Go to login</Link>
        </div>
      </form>
    </BoxContainerUnauthen>
  );
};

export default ChangePassword;
