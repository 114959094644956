import DataTable from "@standard/components/DataTable";
import { UserStatusData } from "data";
import { UserStatus } from "data/userStatus";

const url = "master/user";
const columns = [
  {
    label: "Username",
    name: "username",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Email",
    name: "email",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Name",
    name: "name",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Status",
    name: "status",
    sortable: true,
    align: "center",
    search: { type: "checkbox", inline: true, datasource: UserStatusData },
  },
];

const config = {
  enableActive: true,
  enableEdit: true,
  enableTrash: true,
  onBeforeBind: (data) => {
    return data.map((item) => {
      const { status } = item.values;

      item.values.status = <UserStatus value={status} />
      return item;
    });
  },
};

export default function UserTable() {
  return <DataTable columns={columns} url={url} {...config} />;
}
