import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useState } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { AlertService, AuthService, ErrorService } from "@standard/services";
import { useForm } from "react-hook-form";
import { AuthAPI } from "api";

export default function Profile({ }) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = AuthService.getCurrentUser();

  const defaultValues = {
    id: currentUser._id,
    username: currentUser.username,
    name: currentUser.name,
    email: currentUser.email,
  };

  const reactHookForm = useForm({ defaultValues });

  const onSubmit = (newData) => {
    AlertService.confirm("Are you sure to change data ?").then((res) => {
      if (res === false) return;

      setIsLoading(true);

      AuthAPI.updateCurrentUserProfile(newData)
        .then((result) => {
          setIsLoading(false);
          AlertService.done();
          AuthService.setCurrentUser(result.data);
        })
        .catch((err) => {
          setIsLoading(false);
          if (typeof err === "string") setErrors([err]);
          else {
            console.error(err);
            setErrors(["Unexpected error, please contact admin"])
          }
        });
    });
  };

  const fields = [
    {
      label: "Username",
      name: "username",
      type: "label",
      required: true,
    },
    {
      label: "Name",
      name: "name",
      type: "text",
      required: false,
      col: 6,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
      col: 6,
    },
  ];

  const fieldErrors = ErrorService.convertToArray(
    reactHookForm.formState.errors
  );

  return (
    <BoxContainer loading={isLoading} errors={errors.concat(fieldErrors)}>
      <DataForm
        refForm={reactHookForm}
        fields={fields}
        onSubmit={onSubmit}
        onErrors={(errors) => setErrors(errors)}
      ></DataForm>
    </BoxContainer>
  );
}
