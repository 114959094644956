import React, { useState, useEffect } from "react";
// import TextEditor from '../TextEditor/TextEditor';
import { Form } from "react-bootstrap";
import "./style.css";
import FormInputText from "./FormInputText";
import FormInputSwitch from "./FormInputSwitch";
import FormInputTextArea from "./FormInputTextArea";
import FormInputLabel from "./FormInputLabel";
import FormInputDropdown from "./FormInputDropdown";
import FormInputNumber from "./FormInputNumber";
import FormInputFile from "./FormInputFile";
import FormInputFilePreview from "./FormInputFilePreview";
import FormInputDate from "./FormInputDate";
import FormInputRadio from "./FormInputRadio";
import FormInputAddOn from "./FormInputAddOn";
import FormInputCheckbox from "./FormInputCheckbox";

const $ = window.$;

export const FIELD_INPUT_TYPE = {
  LABEL: "label",
  TEXT: "text",
  PASSWORD: "password",
  EMAIL: "email",
  URL: "url",
  SWITCH: "switch",
  TEXTAREA: "textarea",
  DDL: "ddl",
  FILE: "file",
  FILE_PREVIEW: "filePreview",
  NUMBER: "number",
  DATE: "date",
  RADIO: "radio",
  AUTOCOMPLETE: "autocomplete",
  TIME: "time",
  CHECKBOX: "checkbox",
};


const FormInput = React.forwardRef(({
  field = {}, // type, value, name, required, label, datasource
  onChange = null,
  value = ""
}, ref) => {
  let isShowLabel = field.label !== undefined && field.label !== "";

  const [fieldValue, setFieldValue] = useState(value);

  const onValueChange = ({ name, value, ...params }) => {
    if (onChange !== null) onChange({ name, value, ...params });
    setFieldValue(value);
  };

  useEffect(() => {
    if (value !== fieldValue) {
      setFieldValue(value);
    }
  }, [value, field.datasource]);

  let label = "";
  let input = "";

  switch (field.type) {
    case FIELD_INPUT_TYPE.DATE:
      input = (
        <FormInputDate
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.RADIO:
      input = (
        <FormInputRadio
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.CHECKBOX:
      input = (
        <FormInputCheckbox
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.FILE_PREVIEW:
      input = (
        <FormInputFilePreview
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.FILE:
      input = (
        <FormInputFile
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.SWITCH:
      input = (
        <FormInputSwitch
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.LABEL:
      input = (
        <FormInputLabel ref={ref} field={field} fieldValue={fieldValue} />
      );
      break;
    case FIELD_INPUT_TYPE.TEXTAREA:
      input = (
        <FormInputTextArea
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.DDL:
      input = (
        <FormInputDropdown
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;
    case FIELD_INPUT_TYPE.NUMBER:
      input = (
        <FormInputNumber
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
      break;

    default:
      input = (
        <FormInputText
          ref={ref}
          field={field}
          fieldValue={fieldValue}
          onChange={onValueChange}
        />
      );
  }

  input = <FormInputAddOn field={field}>{input}</FormInputAddOn>;

  if (isShowLabel) {
    const labelClassName = ["col-form-label"];
    if (field.required) labelClassName.push("field-required");

    label = (
      <Form.Label className={labelClassName.join(" ")}>
        {field.label}:
      </Form.Label>
    );
  } else {
    return (
      <>
        {input}
        {field.description && (
          <Form.Text className="text-muted">{field.description}</Form.Text>
        )}
      </>
    );
  }

  const columnMerge = "col-" + (field.col ? field.col : "12");

  const labelContainerClassName = [
    "form-group",
    field.type === "label" || field.inline === true ? "mb-0" : "mb-3",
  ];

  return (
    <div className={columnMerge}>
      <Form.Group
        className={labelContainerClassName.join(" ")}
        controlId={field.name}
      >
        {label} {input}
        {field.description && (
          <Form.Text className="text-muted">{field.description}</Form.Text>
        )}
      </Form.Group>
    </div>
  );
});

export default FormInput;
