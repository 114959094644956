import { UtilsService } from "../../services";
import ErrorContainer from "./ErrorContainer";
import Loader from "./Loader.js";
import { useState, useEffect } from "react";
import Transitions from "./Transitions";

const BoxContainer = ({
  errors = null,
  isLoading = false,
  isShowProjectName = false,
  children,
  title = "",
  description = "",
  onClearError,
}) => {
  const projectName = UtilsService.getProjectName();

  return (
    <Transitions>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center logo-name">{projectName}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mx-auto py-3" style={{ "backgroundColor": "rgba(255, 255, 255, 0.7)", "borderRadius": "25px", }}>
            <div style={{ "padding": "10px 10%" }}>
              {isShowProjectName ? (
                <h3 className="text-center">Welcome to {projectName} System</h3>
              ) : (
                ""
              )}
              {title !== "" ? (
                <h1 className="font-bold text-center">{title}</h1>
              ) : (
                ""
              )}
              {description !== "" ? (
                <div className="mx-auto text-center">
                  <p>{description}</p>
                </div>
              ) : (
                ""
              )}
              {isLoading && <Loader />}
              {errors && (
                <ErrorContainer onClearError={onClearError} errors={errors} />
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </Transitions>
  );
};

export default BoxContainer;
