import { forwardRef } from "react";

const FormInputAddOn = forwardRef(({ field, children }, ref) => {
  const { prefix, postfix } = field;

  let wrapPostfix, wrapPrefix;
  if (postfix) {
    wrapPostfix = (
      <div className="input-group-append">
        <span className="input-group-text">{postfix}</span>
      </div>
    );
  }

  if (prefix) {
    wrapPrefix = <span class="input-group-text">{prefix}</span>;
  }

  if (prefix || postfix) {
    return (
      <div className="input-group input-group-sm mb-1">
        {wrapPrefix}
        {children}
        {wrapPostfix}
      </div>
    );
  }

  return children;
});

export default FormInputAddOn;
