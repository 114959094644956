import CompanyInfo from "./CompanyInfo";
import Tabs from "@standard/components/Tabs";
import Policy from "./Policy";

export default function SettingTabs({ value, onChange }) {
  const items = [
    {
      key: "profileTH",
      label: "Company",
      content: <CompanyInfo value={value} onChange={onChange} active={true} />,
    },
    {
      key: "policy",
      label: "Policy",
      content: (
        <Policy value={value} onChange={onChange} />
      ),
    },
  ];

  return (
    <div className="col-12">
      <Tabs items={items} />
    </div>
  );
}
