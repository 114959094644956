import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { UtilsService } from "@standard/services";

function LayoutWithAuthen({ children }) {
  const projectName = UtilsService.getProjectName();
  const currentYear = new Date().getFullYear();
  return (
    <div id="wrapper">
      <Sidebar></Sidebar>
      <div id="page-wrapper" className="gray-bg">
        <div className="row border-bottom">
          <Navbar></Navbar>
        </div>
        {children}
        <div className="footer">
          <strong>Copyright</strong>&nbsp;{projectName} &copy; {currentYear}
        </div>
      </div>
    </div>
  );
}

export default LayoutWithAuthen;
