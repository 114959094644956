import { useDispatch, useSelector } from "react-redux";
import {
  setSearchCriteria,
  setSelectedAll,
} from "@standard/store/reducers/dataTableSlice";
import { Form } from "react-bootstrap";

function SearchResultHeader({ isSortable = true }) {
  const dispatch = useDispatch();
  const { config, searchCriteria, data, selectedId } = useSelector(
    (state) => state.dataTable
  );
  const { columns, enableCheckbox, enableActive, enableAction } = config;

  const setOrderBy = (item) => {
    let orderBy = [];
    if (
      searchCriteria.orderBy.length > 0 &&
      searchCriteria.orderBy[0] === item.name
    ) {
      if (searchCriteria.orderBy[1] === "desc") {
        orderBy = [item.name, "asc"];
      } else {
        orderBy = [item.name, "desc"];
      }
    } else {
      orderBy = [item.name, "asc"];
    }

    dispatch(setSearchCriteria({ orderBy }));
  };

  var colHeaders = columns.map((item, index) => {
    const className = ["text-center"];
    const sortable = item.sortable && isSortable;

    if (sortable === true) {
      className.push("sortable");
    }

    if (
      searchCriteria.orderBy &&
      searchCriteria.orderBy.length > 0 &&
      searchCriteria.orderBy[0] === item.name
    ) {
      className.push(searchCriteria.orderBy[1]);
    }

    return (
      <th
        className={className.join(" ")}
        key={index}
        onClick={() => {
          if (sortable === true) setOrderBy(item);
        }}
      >
        {item.label}
      </th>
    );
  });

  const checked = data.length > 0 ? data.length === selectedId.length : false;
  let colHeaderCheckbox = (
    <th key={-1} className="text-center">
      {enableCheckbox ? (
        <Form.Check
          inline
          type="checkbox"
          id={`selected_all`}
          checked={checked}
          onChange={(e) => dispatch(setSelectedAll(!checked))}
        />
      ) : (
        "#"
      )}
    </th>
  );

  colHeaders.unshift(colHeaderCheckbox);

  if (enableActive === true) {
    var colHeaderActive = (
      <th key={-2} className="text-center">
        # Active
      </th>
    );
    colHeaders.push(colHeaderActive);
  }

  if (enableAction === true) {
    var colHeaderAction = (
      <th key={-3} className="text-center">
        # Action
      </th>
    );
    colHeaders.push(colHeaderAction);
  }

  return <tr>{colHeaders}</tr>;
}

export default SearchResultHeader;
