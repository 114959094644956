import TableForm from '@standard/components/TableForm'
import { NumberService } from '@standard/services'
import React from 'react'

export default function MemberOrder({ orders }) {
    const fields = [
        { label: "Document No", name: "documentNo", type: "label", align: "center" },
        { label: "Order Date", name: "createdAt", type: "label", align: "center" },
        { label: "Service", name: "serviceType", type: "label", align: "center" },
        { label: "Subservice", name: "subserviceType", type: "label", align: "center" },
        { label: "Payment Status", name: "paymentStatus", type: "label", align: "center" },
        { label: "Status", name: "status", type: "label", align: "center" },
        { label: "Start Destination", name: "startDestination", type: "label", align: "left" },
        { label: "End Destination", name: "endDestination", type: "label", align: "left" },
        { label: "Net", name: "netLabel", type: "label", align: "right" },
    ]
    return (
        <div className='card mb-2'>
            <div className="card-header">
                <h3 className="card-title">Orders</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-6">
                        <strong>Number of Orders:</strong> {NumberService.convertToMoney(orders.length)} Transactions
                    </div>
                    <div className="col-6">
                        <strong>Sum of Total: </strong> {NumberService.convertToMoney(orders.reduce((acc, order) => acc + order.values.net, 0))} Baht
                    </div>
                    <div className="col-12 mt-3">
                        <TableForm fields={fields} values={orders} disabled={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}
