import { memo, useState, useEffect } from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";

const Pagination = ({
  totalPage = 0,
  selectedPage = 1,
  visiblePage = 7,
  onPageChange = {},
  size = "sm", // 'sm', 'lg'
}) => {
  const pageNumber = [];
  const limitPage = visiblePage <= totalPage ? visiblePage : totalPage;

  var startPage = 1;
  var endPage = limitPage;

  if (selectedPage >= visiblePage - 1) {
    if (selectedPage + 1 >= totalPage) {
      startPage = totalPage - 6;
      endPage = totalPage;
    } else {
      startPage = selectedPage - 4;
      endPage = selectedPage + 2;
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumber.push(
      <BootstrapPagination.Item
        key={i}
        active={i === selectedPage}
        disabled={i === selectedPage}
        activeLabel=""
        onClick={() => onPageChange(i)}
      >
        {i}
      </BootstrapPagination.Item>
    );
  }

  return (
    <BootstrapPagination size={size}>
      <BootstrapPagination.First
        onClick={() => onPageChange(1)}
        disabled={selectedPage === 1}
      />
      <BootstrapPagination.Prev
        onClick={() => onPageChange(selectedPage - 1)}
        disabled={selectedPage === 1}
      />
      {pageNumber}
      <BootstrapPagination.Next
        onClick={() => onPageChange(selectedPage + 1)}
        disabled={selectedPage === totalPage}
      />
      <BootstrapPagination.Last
        onClick={() => onPageChange(totalPage)}
        disabled={selectedPage === totalPage}
      />
    </BootstrapPagination>
  );
};

export default memo(Pagination);
