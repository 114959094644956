import { memo } from "react";
import ErrorContainer from "./ErrorContainer.js";
import Loader from "./Loader.js";

const BoxContainer = ({ loading = true, children, errors = [] }) => {
  const onClearError = () => { };

  return (
    <div className="row wrapper wrapper-content animated fadeInRight">
      <div className="col-12">
        <div className="ibox">
          <div
            style={{ paddingBottom: "30px" }}
            className={
              "box-container ibox-content border-top-0 " +
              (loading ? "sk-loading" : "")
            }
          >
            <ErrorContainer
              errors={errors}
              onClearError={onClearError}
              col={12}
            />
            {loading && <Loader />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BoxContainer);
