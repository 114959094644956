import Loader from "@standard/components/BoxContainer/Loader";
import { DateService, NumberService, UtilsService } from "@standard/services";
import { DashboardAPI } from "api";
import { ServiceTypeData } from "data";
import { useEffect, useState } from "react";
const $ = window.$;

const RateUpDown = (props) => {
  const percent = props.value;
  if (percent === 0 || percent === "") return "";
  if (percent > 0) return <i className="fa fa-level-up"></i>;

  return <i className="fa fa-level-down"></i>;
}

function Dashboard() {
  const defaultData = {
    income: {
      today: {
        value: 0,
        percent: 10,
      },
      monthly: {
        value: 0,
        percent: -20,
      }
    },
    order: {
      today: {
        value: 0,
        percent: 0,
      },
      monthly: {
        value: 0,
        percent: 0,
      }
    },
    orders: {
      allOrder: [],
      paidOrder: [],
    }, // order and payment
    orderToday: [], // last 10 orders paid but incomplete sort by last order
    compareVehicle: [], // vehicle mostly
    compareService: [], // service mostly
    counter: {
      visiting: 0,
      orders: 0,
    }
  };

  const [data, setData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    DashboardAPI.getDashboard().then((dashboardData) => {
      setData(prev => {
        return { ...prev, ...dashboardData }
      });

      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      activeDashboard();
    });
  }, [data]);

  const activeDashboard = () => {
    // var numberOfPayment = [
    //   [gd(2012, 1, 31), 25],
    // ];

    // var numberOfOrders = [
    //   [gd(2012, 1, 31), 993],
    // ];

    const numberOfOrders = data.orders.allOrder;
    const numberOfPayment = data.orders.paidOrder;

    var dataset = [
      {
        label: "Number of Orders",
        data: numberOfOrders,
        color: "#1ab394",
        bars: {
          show: true,
          align: "center",
          barWidth: 24 * 60 * 60 * 600,
          lineWidth: 0,
        },
      },
      {
        label: "Payments",
        data: numberOfPayment,
        yaxis: 2,
        color: "#1C84C6",
        lines: {
          lineWidth: 1,
          show: true,
          fill: true,
          fillColor: {
            colors: [
              {
                opacity: 0.2,
              },
              {
                opacity: 0.4,
              },
            ],
          },
        },
        splines: {
          show: false,
          tension: 0.6,
          lineWidth: 1,
          fill: 0.1,
        },
      },
    ];

    var options = {
      xaxis: {
        mode: "time",
        tickSize: [3, "day"],
        tickLength: 0,
        axisLabel: "Date",
        axisLabelUseCanvas: true,
        axisLabelFontSizePixels: 12,
        axisLabelFontFamily: "Arial",
        axisLabelPadding: 10,
        color: "#d5d5d5",
      },
      yaxes: [
        {
          position: "left",
          color: "#d5d5d5",
          axisLabelUseCanvas: true,
          axisLabelFontSizePixels: 12,
          axisLabelFontFamily: "Arial",
          axisLabelPadding: 10,
        },
        {
          position: "right",
          clolor: "#d5d5d5",
          axisLabelUseCanvas: true,
          axisLabelFontSizePixels: 12,
          axisLabelFontFamily: " Arial",
          axisLabelPadding: 67,
        },
      ],
      legend: {
        noColumns: 1,
        labelBoxBorderColor: "#000000",
        position: "nw",
      },
      grid: {
        hoverable: false,
        borderWidth: 0,
      },
    };

    $.plot($("#flot-dashboard-chart"), dataset, options);

    const pie_color = [
      "#87b7ec",
      "#a403a9",
      "#ae858a",
      "#cd4973",
      "#acce61",
      "#9b129e",
      "#6be9da",
      "#67b68f",
      "#7bc01f",
      "#045c69",
      "#631337",
    ];

    var compareVehicle = data.compareVehicle.map((item, itemIndex) => {
      return {
        label: item.vehicleName,
        data: item.total,
        color: pie_color.length < itemIndex ? pie_color[itemIndex] : pie_color[pie_color.length - itemIndex],
      }
    })

    console.log("compareVehicle",)

    $.plot("#flot-pie-chart", compareVehicle, {
      series: {
        pie: {
          show: true,
        },
      },
      grid: {
        hoverable: true,
      },
      tooltip: true,
      tooltipOpts: {
        content: function (label, x, y, serie) {
          const percent = Math.round(serie.datapoint[0]);

          return percent + "% (" + y + "), " + label;
        },
        shifts: {
          x: 20,
          y: 0,
        },
        defaultTheme: false,
      },
      label: {
        show: true,
      },
      legend: {
        position: "ne",
        labelFormatter: function (label, series) {
          var percent = Math.round(series.percent);
          var number = series.data[0][1]; //kinda weird, but this is what it takes
          return (
            "&nbsp;<b>" + label + "</b>:&nbsp;" + percent + "% (" + number + ")"
          );
        },
      },
    });

    var compareService = data.compareService.map((item, itemIndex) => {
      const label = ServiceTypeData.find(f => f.key === item._id)?.label ?? "";

      return {
        label: label,
        data: item.total,
        color: pie_color.length < itemIndex ? pie_color[itemIndex] : pie_color[pie_color.length - itemIndex],
      }
    })

    $.plot("#flot-pie-chart-2", compareService, {
      series: {
        pie: {
          show: true,
        },
      },
      grid: {
        hoverable: true,
      },
      tooltip: true,
      tooltipOpts: {
        content: function (label, x, y, serie) {
          const percent = Math.round(serie.datapoint[0]);

          return percent + "% (" + y + "), " + label;
        },
        shifts: {
          x: 20,
          y: 0,
        },
        defaultTheme: false,
      },
      label: {
        show: true,
      },
      legend: {
        position: "ne",
        labelFormatter: function (label, series) {
          var percent = Math.round(series.percent);
          var number = series.data[0][1]; //kinda weird, but this is what it takes
          return (
            "&nbsp;<b>" + label + "</b>:&nbsp;" + percent + "% (" + number + ")"
          );
        },
      },
    });
  }

  const today = DateService.convertDateToString(new Date());

  return (
    <>
      <div className={"wrapper wrapper-content box-container"}>
        {isLoading && <Loader />}
        <div className="row">
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-success float-right">
                    Monthly
                  </span>
                </div>
                <h5>Income</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">{NumberService.convertToMoney(data.income.monthly.value)}</h1>
                <div className="stat-percent font-bold text-info">
                  {NumberService.convertToMoney(data.income.monthly.percent)}% <RateUpDown value={data.income.monthly.percent} />
                </div>
                <small>Total income</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-primary float-right">
                    Today
                  </span>
                </div>
                <h5>Income</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">{NumberService.convertToMoney(data.income.today.value)}</h1>
                <div className="stat-percent font-bold text-info">
                  {NumberService.convertToMoney(data.income.today.percent)}% <RateUpDown value={data.income.today.percent} />
                </div>
                <small>Total income</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-success float-right">
                    Monthly
                  </span>
                </div>
                <h5>Orders</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">{NumberService.convertToMoney(data.order.monthly.value)}</h1>
                <div className="stat-percent font-bold text-info">
                  {NumberService.convertToMoney(data.order.monthly.percent)}% <RateUpDown value={data.order.monthly.percent} />
                </div>
                <small>New orders</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="ibox ">
              <div className="ibox-title">
                <div className="ibox-tools">
                  <span className="label label-primary float-right">
                    Today
                  </span>
                </div>
                <h5>Orders</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">{NumberService.convertToMoney(data.order.today.value)}</h1>
                <div className="stat-percent font-bold text-info">
                  {NumberService.convertToMoney(data.order.today.percent)}% <RateUpDown value={data.order.today.percent} />
                </div>
                <small>New orders</small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Visiting</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">{NumberService.convertToMoney(data.counter.visiting)}</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Number of Orders</h5>
              </div>
              <div className="ibox-content">
                <h1 className="no-margins">{NumberService.convertToMoney(data.counter.orders)}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Orders vs Payment</h5>
                <div className="ibox-tools">
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="flot-chart">
                      <div
                        className="flot-chart-content"
                        id="flot-dashboard-chart"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Vehicle by Order</h5>
              </div>
              <div className="ibox-content">
                <div className="flot-chart">
                  <div
                    className="flot-chart-content"
                    id="flot-pie-chart"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Service by Order</h5>
              </div>
              <div className="ibox-content">
                <div className="flot-chart">
                  <div
                    className="flot-chart-content"
                    id="flot-pie-chart-2"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox ">
              <div className="ibox-title">
                <h5>Orders Remaining</h5>
                <div className="ibox-tools">
                  <a className="collapse-link">
                    <i className="fa fa-chevron-up"></i>
                  </a>
                  <a className="close-link">
                    <i className="fa fa-times"></i>
                  </a>
                </div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-hover no-margins">
                  <thead>
                    <tr>
                      <th>Document No</th>
                      <th>Order Date</th>
                      <th>Name</th>
                      <th>Telephone</th>
                      <th>Start Destination</th>
                      <th>End Destination</th>
                      <th>Net</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.orderToday.map(item => {
                      return <tr key={item._id}>
                        <td className="text-center">
                          <a href={`/transaction/order/edit/${item._id}`} target="_blank" className="text-link btn btn-link">{item.documentNo}</a></td>
                        <td className="text-center">{DateService.convertDateTimeToString(item.orderDate)}</td>
                        <td className="text-left">{item.name}</td>
                        <td className="text-left">{item.telephone}</td>
                        <td className="text-left">{item.startDestination}</td>
                        <td className="text-left">{item.endDestination}</td>
                        <td className="text-right">{NumberService.convertToMoney(item.net)} บาท</td>
                      </tr>
                    })}
                    {data.orderToday.length === 0 && <tr><td colSpan={7} className="text-center">No Data</td></tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
