import { forwardRef } from "react";
import Dropdown from "../Dropdown";

const FormInputDropdown = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const {
    name,
    createable,
    datasource,
    multiple,
    label,
    placeholder,
    disabled,
  } = field;

  return (
    <Dropdown
      ref={ref}
      multiple={multiple}
      id={name}
      name={name}
      datasource={datasource}
      disabled={disabled}
      placeholder={placeholder ?? label}
      value={fieldValue}
      onChange={(e) => {
        // if (multiple) {
        //   // onValueChange({
        //   //   name: field.name,
        //   //   value: e[field.name],
        //   //   isNew: e.isNew,
        //   // });
        // } else {
        //   // onValueChange({
        //   //   name: field.name,
        //   //   value: e[field.name],
        //   //   isNew: e.isNew,
        //   // });
        // }

        onChange({ name, value: e[name], isNew: e.isNew });
      }}
      createable={createable}
    />
  );
});

export default FormInputDropdown;
