import axios from "../axios";
import { AlertService } from ".";
const $ = window.$;

function goToTop() {
  var body = $("html, body");
  body.stop().animate({ scrollTop: 0 }, 500, "swing");
}

function submitRequestWithFile(url, params) {
  const paramsRequest = {
    url,
    params,
  };

  const promise = new Promise((resolve, reject) => {
    let request;
    const id = paramsRequest.params.id;
    const url = paramsRequest.url;
    const requestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let formData = convertToFormData(params);

    if (id !== undefined && id !== null && id !== "" && id !== 0) {
      // Update
      formData.append("_method", "PUT");
      request = axios.put(`${url}/${id}`, formData, requestOptions);
    } else {
      // Insert
      request = axios.post(url, formData, requestOptions);
    }

    request
      .then((result) => {
        AlertService.done("Save complete").then(() => resolve(result));
      })
      .catch((res) => {
        var msg = [];
        if (res.status === 400) {
          if (res.message instanceof Array) {
            msg = res.message;
          } else {
            msg = [res.message];
          }
        } else {
          console.log("Unexpected error was found", res);
          AlertService.error(
            "Unexpected error was found, please contact admin"
          );
        }

        goToTop();

        reject(msg);
      });
  });

  return promise;
}

function getRequest(url, id = "") {
  const getErrorMessages = (message) => {
    return Array.isArray(message) ? message : [message];
  };

  const promise = new Promise((resolve, reject) => {
    if (id instanceof Object) {
      axios
        .get(`${url}\\${Object.values(id).join("\\")}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          AlertService.error("Unexpected error, please contact admin").then(
            () => {
              window.history.back();
            }
          );
          // reject(getErrorMessages(err.message));
        });
    } else {
      axios
        .get(`${url}${id ? "\\" + id : ""}`)
        .then((res) => resolve(res.data))
        .catch((err) => {
          AlertService.error("Unexpected error, please contact admin").then(
            () => {
              window.history.back();
            }
          );
          // reject(getErrorMessages(err.message));
        });
    }
  });

  return promise;
}

function submitRequest(url, params = []) {
  const paramsRequest = {
    url,
    params,
  };

  const promise = new Promise((resolve, reject) => {
    let request;
    const id = paramsRequest.params.id;
    const url = paramsRequest.url;

    if (id !== undefined && id !== null && id !== "" && id !== 0) {
      // Update
      request = axios.put(`${url}/${id}`, paramsRequest.params);
    } else {
      // Insert
      request = axios.post(url, paramsRequest.params);
    }

    request
      .then((result) => {
        AlertService.done("Save complete").then(() => resolve(result));
      })
      .catch((res) => {
        var msg = [];
        if (res.status === 400) {
          if (res.message instanceof Array) {
            msg = res.message;
          } else {
            msg = [res.message];
          }
        } else {
          console.log("Unexpected error was found", res);
          AlertService.error(
            "Unexpected error was found, please contact admin"
          );
        }

        goToTop();

        reject(msg);
      });
  });

  return promise;
}

const convertToFormData = (params) => {
  let formData = new FormData();

  for (var key in params) {
    let value = params[key];
    if (value === null) {
      value = "";
    }

    if (value instanceof Array) {
      formData.append(key, JSON.stringify(value));
    } else if (value instanceof FileList) {
      for (let i = 0; i < value.length; i++) {
        formData.append(key, value[i]);
      }
    } else {
      if (value === undefined) formData.append(key, null);
      else formData.append(key, value);
    }
  }

  return formData;
};

export default {
  submitRequest,
  submitRequestWithFile,
  getRequest,
  convertToFormData,
};
