import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { DatasourceAPI, UserAPI } from "api";
import { AlertService, ErrorService, FormService } from "@standard/services";
import { useEffect } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { USER_ROLE, USER_STATUS } from "data/constants";
import { Controller, useForm } from "react-hook-form";

const onResetPassword = (id) => {
  AlertService.confirm("Do you want to reset password ?").then((res) => {
    if (res) {
      UserAPI.resetPassword(id).then(() =>
        AlertService.done(
          "Link for reseting password was sending to your email, please check your email"
        )
      );
    }
  });
};

const onUnlock = (id) => {
  AlertService.confirm("Do you want to unlocked ?").then((res) => {
    if (res) {
      UserAPI.unlocked(id).then(() =>
        AlertService.done("This user account was changed status to Active")
      );
    }
  });
};

export default function UserForm({ history, url }) {
  const { id, action } = useParams();
  const [buttons, setButtons] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [datasource, setDatasource] = useState({
    menuprofile: [],
  });

  const defaultValues = {
    id: id,
    username: "",
    password: "",
    confirmPassword: "",
    name: "",
    email: "",
    active: true,
    status: USER_STATUS.ACTIVE,
  };

  const reactHookForm = useForm({ defaultValues });

  useEffect(() => {
    if (id !== undefined) {
      retrieveData(id);
    } else {
      const tables = ["menuprofile"];
      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setDatasource((prev) => {
          return { ...prev, ...datasourceResult };
        });

        setIsLoading(false);
      });
    }
  }, []);

  const retrieveData = (id) => {
    FormService.getRequest(url, id).then((result) => {
      const tables = [{ name: "menuprofile", value: result.menuProfileId }];
      DatasourceAPI.getActiveDatasource(tables).then((datasourceResult) => {
        setDatasource((prev) => {
          return { ...prev, ...datasourceResult };
        });

        if (result.status === USER_STATUS.LOCKED) {
          setButtons([
            <DropdownButton
              key={1}
              id="dropdown-basic-button"
              title="Options"
              variant="secondary"
              className="d-inline-block mr-2"
            >
              <Dropdown.Item eventKey="2" onClick={() => onResetPassword(id)}>
                Reset Password
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => onUnlock(id)}>
                Unlock Account
              </Dropdown.Item>
            </DropdownButton>,
          ]);
        }

        reactHookForm.reset(result);
        setIsLoading(false);
      });
    });
  };

  const saveData = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: id };

    FormService.submitRequest(url, params)
      .then(() => {
        setIsLoading(false);
        setErrors([]);
        history.push(`/${url}`);
      })
      .catch((err) => {
        console.log("Catch error", err);
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = (newData) => {
    AlertService.confirm("Do you want to save data").then((res) => {
      if (res === true) saveData(newData);
    });
  };

  let fields = [
    {
      label: "Username",
      name: "username",
      type: id === undefined ? "text" : "label",
      required: true,
    },
  ];

  if (id === undefined) {
    fields.push({
      label: "Password",
      name: "password",
      type: "password",
      required: true,
      col: 6,
    });

    fields.push({
      label: "Confirm password",
      name: "confirmPassword",
      type: "password",
      required: true,
      validate: {
        mustEqualToPassword: (val) => {
          if (val !== reactHookForm.getValues("password"))
            return "Confirm Password must be equal to New Password.";

          return true;
        },
      },
      col: 6,
    });
  }

  fields = fields.concat([
    {
      label: "Name",
      name: "name",
      type: "text",
      required: false,
      col: 6,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
      col: 6,
    },
    {
      label: "Active",
      name: "active",
      type: "switch",
    },
  ]);

  const fieldErrors = ErrorService.convertToArray(
    reactHookForm.formState.errors
  );

  return (
    <BoxContainer loading={isLoading} errors={errors.concat(fieldErrors)}>
      <DataForm
        refForm={reactHookForm}
        onSubmit={onSubmit}
        fields={fields}
        url={url}
        buttons={buttons}
      ></DataForm>
    </BoxContainer>
  );
}
