import React, { useMemo } from 'react'
import FormInput from '@standard/components/FormInput/FormInput';
import { NumberService } from '@standard/services';
import { SERVICE_TYPE } from 'data/constants';

const GoogleMap = ({ location }) => {
    if (!location) return;

    const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const googleMapURL = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAP_API_KEY}&q=${location.lat},${location.lng}&language=en&region=US`;

    return (<iframe
        width="100%"
        height="450"
        style={{ "border": "0" }}
        loading="lazy"
        allowFullScreen
        src={googleMapURL}>
    </iframe>)
}

const GoogleMapDirection = ({ locationSource, locationDestination }) => {
    if (!locationSource || !locationDestination) return;

    const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const googleMapURL = `https://www.google.com/maps/embed/v1/directions?key=${GOOGLE_MAP_API_KEY}&origin=${locationSource.lat},${locationSource.lng}&destination=${locationDestination.lat},${locationDestination.lng}&mode=driving&language=en&region=US`;

    return (<iframe
        width="100%"
        height="450"
        style={{ "border": "0" }}
        loading="lazy"
        allowFullScreen
        src={googleMapURL}>
    </iframe>)

}

export default function OrderFormPlace(props) {
    let fields = [];

    if (props.serviceType === SERVICE_TYPE.LIMOUSINE) {
        fields = [
            { name: "startDestination", label: "Pickup", type: "label", col: 12, value: props.startDestination },
            { name: "endDestination", label: "Dropoff", type: "label", col: 6, value: props.endDestination },
            { name: "distance", label: "Distance", type: "label", col: 12, value: `${NumberService.convertToMoney(props.distance / 1000)} KM.` },
            { name: "direction", type: "custom", control: <GoogleMapDirection key="direction" locationSource={props.startDestinationLocation} locationDestination={props.endDestinationLocation} /> },
        ];
    } else if (props.serviceType === SERVICE_TYPE.DISPOSAL) {
        fields = [
            { name: "startDestination", label: "Pickup", type: "label", col: 12, value: props.startDestination },
            { name: "startDestinationLocation", type: "custom", control: <GoogleMap key="startDestinationLocation" location={props.startDestinationLocation} /> },
        ];
    }

    return (
        <div className="col-12 mt-2">
            <div className="card">
                <div className="card-header bg-secondary text-white">
                    <h3>Place Info</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        {fields.map(field => field.type === "custom" ? field.control : <FormInput key={field.name} field={field} value={field.value ?? ""} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}
