import { forwardRef } from "react";
import NumberFormat from "react-number-format";

const FormInputNumber = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { name, label, disabled, thousandSeparator = true } = field;

  let decimal = 0;
  if (field.decimal) {
    decimal = parseInt(field.decimal);
  }

  return (
    <NumberFormat
      ref={ref}
      value={fieldValue}
      thousandSeparator={thousandSeparator}
      className="form-control"
      id={name}
      name={name}
      prefix={""}
      decimalScale={decimal}
      decimalSeparator="."
      disabled={disabled}
      onValueChange={(values) => {
        const { formattedValue, value } = values;
        onChange({ name, value });
        // if (field.onChange) field.onChange({ [field.name]: value });
        // formattedValue = $2,223
        // value ie, 2223
        // this.setState({ profit: formattedValue });
      }}
    />
  );
});

export default FormInputNumber;
