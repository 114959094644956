import React from 'react'
import FormInput from '@standard/components/FormInput/FormInput';

export default function OrderFormGeneral(props) {
    const fields = [
        { name: "documentNo", label: "Document No", type: "label", col: 6, value: props.documentNo },
        { name: "orderDate", label: "Order Date", type: "label", col: 6, value: props.orderDate },
        {
            name: "paymentStatus",
            label: "Payment Status",
            type: "label",
            col: 6,
            value: props.paymentStatus
        },
        { name: "status", label: "Status", type: "label", col: 6, value: props.status },
        { name: "total", label: "Total", type: "label", col: 12, value: `${props.total} ฿` },
        { name: "discount", label: "Discount", type: "label", col: 12, value: `${props.discount} ฿` },
        { name: "net", label: "Net", type: "label", col: 12, value: `${props.net} ฿` },
        { name: "couponCode", label: "Coupon", type: "label", col: 12, value: props.couponCode },
    ];

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header bg-info">
                    <h3>General</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        {fields.map(field => <FormInput key={field.name} field={field} value={field.value ?? ""} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}
