import DataTable from "@standard/components/DataTable";
import { LinkButton } from "@standard/components/Button";
import { NumberService } from "@standard/services";

const columns = [
  {
    label: "Ref No",
    name: "refno",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Order No",
    name: "orderno",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Product Detail",
    name: "productdetail",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Total",
    name: "total",
    align: "right",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Status",
    name: "statusname",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Order Document No",
    name: "orderDocumentNo",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Error Message",
    name: "errorMessage",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
];

const config = {
  enableActive: false,
  enableEdit: false,
  enableInsert: false,
  enableTrash: false,
  enableView: true,
  onBeforeBind: (data) => {
    return data.map((item) => {
      const { orderDocumentNo, orderId, total } = item.values;

      item.values.orderDocumentNo = orderId ? <LinkButton label={orderDocumentNo} url={`/transaction/order/view/${orderId}`} /> : "-"
      // item.values.total = NumberService.convertToMoney(total);

      return item;
    });
  },
};

export default ({ url }) => {
  // const dispatch = useDispatch();

  const buttons = [
    // {
    //   variant: "danger",
    //   label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
    //   onClick: () => {
    //     const printUrl = `${axios.defaults.baseURL}${url}/export`;
    //     const printUrlWithParam = dispatch(getURLSearchCriteria(printUrl));

    //     console.log("printUrlWithParam", printUrlWithParam, printUrl);

    //     window.open(printUrlWithParam, "_blank");
    //   },
    // },
  ];

  config.buttons = buttons;


  return <DataTable columns={columns} url={url} {...config} />;
};
