import moment from "moment";

export default {
  getThaiDateFullFormat,
  convertStringToDate,
  convertStringToDateTime,
  convertDateToString,
  convertDateTimeToString,
  convertDateToServer,
  withoutTime,
  dateDiff,
  addDays,
  getToday
};

export const FULL_MONTHS_TH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

export const DATE_FORMAT = {
  FORMAT: "DD/MM/YYYY",
  FORMAT_WITH_TIME: "DD/MM/YYYY HH:mm:ss",
  FORMAT_TO_SERVER: "YYYY-MM-DD",
  FORMAT_TO_SERVER_WITH_TIME: "YYYY-MM-DD HH:mm:ss",
};

function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

function getToday(day = 0) {
  const date = new Date();

  date.setDate(date.getDate() + day);

  return date;
}

function getThaiDateFullFormat(_date) {
  if (_date === null) return "";
  let date = convertStringToDate(_date);

  var day = date.getDate();
  var month = FULL_MONTHS_TH[date.getMonth()];
  var year = date.getFullYear() + 543;
  return day + " " + month + " " + year;
}

function withoutTime(dateTime) {
  // var date = new Date(dateTime.getTime());
  // date.setUTCHours(0, 0, 0, 0);
  // return date;
  return moment.utc(dateTime).startOf("day").toDate();
}

function convertStringToDate(_date, format = DATE_FORMAT.FORMAT) {
  if (_date) {
    let date = moment.utc(_date, format).toDate();
    if (isNaN(date)) {
      date = moment.utc(_date, DATE_FORMAT.FORMAT_TO_SERVER).toDate();
    }

    return date;
  }

  return null;
}
function convertStringToDateTime(_date, format = DATE_FORMAT.FORMAT_WITH_TIME) {
  if (_date) {
    let date = moment(_date, format).toDate();
    if (isNaN(date)) {
      date = moment(_date, DATE_FORMAT.FORMAT_TO_SERVER_WITH_TIME).toDate();
    }

    return date;
  }

  return null;
}

function convertDateToString(_date, format = DATE_FORMAT.FORMAT) {
  return convertDateTimeToString(_date, format);
}
function convertDateTimeToString(_date, format = DATE_FORMAT.FORMAT_WITH_TIME) {
  if (_date) {
    // let date = moment.utc(_date).format(format);
    let date = moment(_date).format(format);

    return date;
  }

  return null;
}

function convertDateToServer(_date, format = DATE_FORMAT.FORMAT_TO_SERVER) {
  return _date ? moment(_date, DATE_FORMAT.FORMAT).format(format) : null;
}

function dateDiff(first, second, type = "days") {
  // type: years, months, weeks, days, hours, minutes, and seconds
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.

  let firstDate;
  let secondDate;

  if (first instanceof Date) firstDate = moment(first);
  else
    firstDate = moment(
      first.replaceAll("-", "/"),
      DATE_FORMAT.FORMAT_WITH_TIME
    );

  if (second instanceof Date) secondDate = moment(second);
  else
    secondDate = moment(
      second.replaceAll("-", "/"),
      DATE_FORMAT.FORMAT_WITH_TIME
    );

  return secondDate.diff(firstDate, type);
}
