import { forwardRef } from "react";
import DatePicker from "../DatePicker";

const FormInputDate = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { name, label, placeholder, disabled, isRange } = field;

  return (
    <DatePicker
      name={name}
      onChange={({ value }) => onChange({ name, value })}
      value={fieldValue}
      isRange={isRange}
      disabled={disabled}
      placeholder={placeholder || label}
    />
  );
});

export default FormInputDate;
