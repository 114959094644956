import React from 'react'

const SocialMediaIcon = ({ provider }) => {
    switch (provider) {
        case "facebook": return <span className="h5 mr-2"><i className="fa fa-facebook-official"></i></span>
        case "google": return <span className="h5 mr-2"><i className="fa fa-google"></i></span>
    }

    return "";
}

export default function ({ values }) {
    return (
        values?.map(value =>
            <SocialMediaIcon provider={value.provider} />
        )
    )
}
