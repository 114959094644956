export const USER_STATUS = {
  ACTIVE: "active",
  LOCKED: "locked",
  PENDING: "pending",
};

export const USER_ROLE = {
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  USER: "user",
};

export const PROGRAM_CODE = {
  MASTER_USER: "MT01",
  MASTER_SETTING: "MT02",
};

export const SERVICE_TYPE = {
  LIMOUSINE: "limousine",
  DISPOSAL: "disposal",
  AIRPORT: "airport",
};

export const SUBSERVICE_TYPE = {
  LIMOUSINE_TRANSFER: "transfer",
  LIMOUSINE_POINT_TO_POINT: "pointtopoint",
  DISPOSAL_WITHIN: "within",
  DISPOSAL_OUTSIDE: "outside",
};
