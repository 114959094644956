import { UtilsService } from "@standard/services";
import { forwardRef } from "react";

const FormInputLabel = forwardRef(({ field, fieldValue }, ref) => {
  if (UtilsService.isReactElement(fieldValue))
    return fieldValue;
  else
    return UtilsService.htmlParse(fieldValue);
});

export default FormInputLabel;
