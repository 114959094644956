import { USER_STATUS } from "data/constants";
import { memo } from "react";

const data = [
    { key: USER_STATUS.PENDING, label: "Pending" },
    { key: USER_STATUS.ACTIVE, label: "Active" },
    { key: USER_STATUS.LOCKED, label: "Locked" },
];

export default data;

export const UserStatus = memo(({ value }) => {
    const label = data.find((f) => f.key === value)?.label ?? "";
    let className = ["badge p-2"];

    switch (value) {
        case USER_STATUS.PENDING:
            className.push("badge-warning");
            break;
        case USER_STATUS.ACTIVE:
            className.push("badge-primary");
            break;
        case USER_STATUS.LOCKED:
            className.push("badge-danger");
            break;
    }

    return <span className={className.join(" ")}>{label}</span>;
});