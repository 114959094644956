import { useContext, memo } from "react";
import { DataFormContext } from "./DataForm";
import { Link } from "react-router-dom";

const DataFormButtonGroup = ({}) => {
  const { url, enableSave, buttons } = useContext(DataFormContext);

  let urlBack;
  if (url !== "" && url !== undefined) {
    urlBack = (url.startsWith("/") === false ? "/" : "") + url;
  }

  var backButton;
  if (urlBack !== undefined && urlBack !== "") {
    backButton = (
      <Link className="btn btn-white" to={urlBack}>
        Back
      </Link>
    );
  }

  var saveButton;
  var saveAndBackButton;
  if (enableSave) {
    saveButton = (
      <button
        className={"btn btn-primary mr-2"}
        name="submit"
        value="submit"
        type="submit"
      >
        Save
      </button>
    );

    // saveAndBackButton = (
    //   <button
    //     className={"btn btn-primary mr-2"}
    //     name="submit"
    //     value="submit"
    //     type="submit"
    //   >
    //     Save and Back
    //   </button>
    // );
  }

  return (
    <div className="col-12 col-md-8 text-right">
      {buttons}
      {saveButton}
      {saveAndBackButton}
      {backButton}
    </div>
  );
};

export default memo(DataFormButtonGroup);
