import TitleHeader from "@standard/components/TitleHeader";
import VehicleForm from "./VehicleForm";
import VehicleTable from "./VehicleTable";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Master";
const programName = "Vehicle";
const url = "master/vehicle";

export default function Vehicle({ history }) {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <VehicleTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <VehicleForm history={history} url={url} />
        </Route>
      </Switch>
    </>
  );
}
