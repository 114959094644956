export default function index({ items = [] }) {
  // items = { key = "", label = ""}
  return (
    <div>
      <ul className="nav nav-tabs" role="tablist">
        {items.map((item, itemIndex) => (
          <li className="nav-item" role="presentation" key={itemIndex}>
            <button
              className={`nav-link ${itemIndex === 0 ? "active" : ""}`}
              // id="home-tab"
              data-toggle="tab"
              data-target={`#${item.key}`}
              type="button"
              role="tab"
              aria-controls={`${item.key}`}
              aria-selected="true"
            >
              {item.label}
            </button>
          </li>
        ))}
        {/* <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="profile-tab"
            data-toggle="tab"
            data-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            Profile
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="contact-tab"
            data-toggle="tab"
            data-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            Contact
          </button>
        </li> */}
      </ul>
      <div className="tab-content pt-3">
        {items.map((item, itemIndex) => (
          <div
            key={itemIndex}
            className={`tab-pane fade ${itemIndex === 0 ? "show active" : ""}`}
            id={item.key}
            role="tabpanel"
            aria-labelledby={item.key}
          >
            {item.content}
          </div>
        ))}
        {/* <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          ...
        </div>
        <div
          className="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          ...
        </div>
        <div
          className="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          ...
        </div> */}
      </div>
    </div>
  );
}
