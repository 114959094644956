import { forwardRef } from "react";
import Switcher from "./../Switcher/Switcher";

const FormInputSwitch = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { name, disabled } = field;

  return (
    <Switcher
      ref={ref}
      name={name}
      checked={fieldValue}
      disabled={disabled}
      onChange={(value) => onChange({ name, value })}
    />
  );
});

export default FormInputSwitch;
