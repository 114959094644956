import FormInput from "@standard/components/FormInput/FormInput";

export default function CompanyInfo({ value, onChange }) {
  const fields = [
    { name: "companyOwner", label: "Owner Name", type: "text" },
    { name: "companyName", label: "Company Name", type: "text" },
    { name: "companyAddress", label: "Company Address", type: "textarea" },
    { name: "companyAddress2", label: "Company Address 2", type: "textarea" },
    { name: "companyZipCode", label: "Postal Code", type: "text" },
    { name: "companyTelephone", label: "Telephone", type: "text" },
    { name: "companyEmail", label: "Email", type: "email" },
    { name: "companyTaxNo", label: "Tax Number", type: "text" },
  ];

  return (
    <div>
      {fields.map((field) => (
        <FormInput
          field={field}
          key={field.name}
          value={value[field.name]}
          onChange={onChange}
        />
      ))}{" "}
    </div>
  );
}
