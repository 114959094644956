import { Form } from "react-bootstrap";
import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import DataFormHeader from "./DataFormHeader";
import DataFormFooter from "./DataFormFooter";
import DataFormContent from "./DataFormContent";
import { Controller, useForm } from "react-hook-form";
import { UtilsService } from "@standard/services";
import _ from "lodash";

export const DataFormContext = React.createContext({});

const DataForm = ({
  buttons = [],
  children,
  hideSeperateLine = false,
  url,
  fields = [],
  tabs = [],
  // onChange,
  // onErrors,
  enableTabs = false,
  enableSave = true,
  onSubmit = null,
  leftPanel = null,
  // defaultValues,
  refForm,
}) => {
  const onSubmitForm = (data) => {
    if (onSubmit !== undefined) {
      onSubmit(data);
    }
  };

  // const defaultValues = {};
  // const {
  //   setValue,
  //   register,
  //   handleSubmit,
  //   reset,
  //   getValues,
  //   formState: { errors },
  //   watch,
  // } = useForm({ defaultValues });

  // useEffect(() => {
  //   reset(defaultValues);
  // }, [defaultValues]);

  // useEffect(() => {
  //   if (!_.isEmpty(errors)) {
  //     const errorMessages = [];

  //     for (const key in errors) {
  //       const error = errors[key];
  //       let label = "";
  //       if (enableTabs) {
  //         fields.map((m) => {
  //           const field = m.fields.find((f) => f.name === key);
  //           if (field) label = field.label;
  //         });
  //       } else {
  //         label = fields.find((f) => f.name === key).label;
  //       }

  //       if (error.type === "required") {
  //         errorMessages.push(`${label} is required`);
  //       }
  //     }

  //     onErrors(errorMessages);
  //   }
  // }, [JSON.stringify(errors)]);

  const contextValues = {
    url,
    enableSave,
    buttons,
  };

  return (
    <DataFormContext.Provider value={contextValues}>
      <Form
        onSubmit={refForm ? refForm.handleSubmit(onSubmitForm) : onSubmitForm}
      >
        <DataFormHeader leftPanel={leftPanel} />
        {hideSeperateLine && <div className="hr-line-dashed"></div>}
        <DataFormContent
          fields={fields}
          refForm={refForm}
          tabs={tabs}
          enableTabs={enableTabs}
        />
        {children}
        {hideSeperateLine && <div className="hr-line-dashed"></div>}
        <DataFormFooter />
      </Form>
    </DataFormContext.Provider>
  );
};

export default memo(DataForm);
