import React, { useState, useEffect } from "react";

import "./DataTable.css";
import SearchCriteria from "./SearchCriteria";
import SearchResult from "./SearchResult";
import SearchTool from "./SearchTool";
import Pagination from "./../Pagination/Pagination";
import {
  setConfig,
  changePageNumber,
  fetchData,
  setSearchCriteria,
  clearSearchCriteria,
} from "@standard/store/reducers/dataTableSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../BoxContainer/Loader";
import _ from "lodash";

const defaultProps = {
  columns: [
    {
      label: "column #1",
      align: "left",
      name: "col1",
      searchType: "string",
      sortable: false,
      search: {
        range: false,
        type: "string",
        datasource: [{ key: "", label: "" }],
      },
    },
  ],
  url: "", // API Url
  enableActive: false,
  enablePrint: false,
  enableInsert: true,
  enableTrash: true,
  enableView: false,
  enableEdit: true,
  buttonEditLabel: null,
  enableCheckbox: false,
  enableAction: true,
  enableImport: false,
  onPrintClick: [],
  buttons: [], // [<button onClick={() => doSomething()}></button>]
  buttonsRow: [],
  beforeBind: null,
  isLoading: false,
  onFetchData: null, // fn
};


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DataTable = (componentProps) => {
  let props = { ...defaultProps, ...componentProps };
  let query = useQuery();
  const dispatch = useDispatch();

  let location = useLocation();
  useEffect(() => {
    dispatch(clearSearchCriteria());
  }, [location.pathname]);

  const {
    searchCriteria,
    isLoading,
    totalRecord,
    totalPage,
    startRowRunning,
    endRowRunning,
  } = useSelector((state) => state.dataTable);

  useEffect(() => {
    dispatch(setConfig({ ...props }));
  }, [JSON.stringify(props.customFilters)]);

  useEffect(() => {
    if (props.isLoading === false) {
      dispatch(fetchData());
      if (props.onFetchData instanceof Function) {
        props.onFetchData(searchCriteria);
      }
    }
  }, [JSON.stringify(searchCriteria), props.isLoading]);

  const onPageChange = (pageNumber) => {
    dispatch(changePageNumber(pageNumber));
  };

  const table = (
    <div className="col-12">
      <div className="ibox">
        <div className={"ibox-content"}>
          {(props.isLoading || isLoading) && <Loader />}
          <div className="mb-2">
            <SearchCriteria />
          </div>
          <SearchTool />
          {props.children}
          <SearchResult />
          <div className="pull-right">
            <Pagination
              totalPage={totalPage}
              selectedPage={searchCriteria.pageNumber}
              onPageChange={onPageChange}
            />
          </div>
          <div className="pull-left">
            Showing {startRowRunning} to {endRowRunning} of {totalRecord}{" "}
            records
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="row wrapper wrapper-content animated fadeInRight"
      style={{ minHeight: "800px" }}
    >
      {table}
    </div>
  );
};
// }

export default DataTable;
