import React from 'react'
import FormInput from '@standard/components/FormInput/FormInput';

export default function OrderFormPayment(props) {
    const fields = [
        { name: "paymentStatus", label: "Payment Status", type: "label", col: 12, value: props.paymentStatus },
        { name: "refno", label: "Ref No (Pay Solution)", type: "label", col: 12, value: props.refno },
    ];

    return (
        <div className="col-12 mt-2">
            <div className="card">
                <div className="card-header bg-danger">
                    <h3>Payment</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        {fields.map(field => <FormInput key={field.name} field={field} value={field.value ?? ""} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}
