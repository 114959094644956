import User from "./User";
import Setting from "./Setting";
import Member from "./Member";
import Vehicle from "./Vehicle";

export default {
  User,
  Setting,
  Member,
  Vehicle,
};
