import { Link } from "react-router-dom";
import { AuthService } from "@standard/services";
import { useForm } from "react-hook-form";
import { BoxContainerUnauthen } from "@standard/components/BoxContainer";
import _ from "lodash";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";

const defaultValues = {
  username: "",
  password: "",
  remember: false,
};

const Login = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const { register, formState, handleSubmit } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    var params = {
      username: data.username,
      password: data.password,
      remember: data.remember,
    };

    AuthService.login(params)
      .then(() => {
        setIsLoading(false);
        history.push("/");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        setErrors(error);
      });
  };

  const _errors = [];
  for (var i in formState.errors) _errors.push(formState.errors[i].message);

  return (
    <BoxContainerUnauthen
      isLoading={isLoading}
      errors={_errors.concat(errors)}
      isShowProjectName={true}
      onClearError={() => setErrors([])}
    >
      <Form className="m-t" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            type="text"
            {...register("username", {
              required: "Please enter your username or email.",
            })}
            placeholder="Username / Email"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            {...register("password", {
              required: "Please enter your password.",
            })}
            placeholder="Password"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <Button
            variant="primary"
            type="submit"
            size="lg"
            className="block full-width"
          >
            Login
          </Button>
        </div>
        <div className="form-group">
          {/* <div className="float-left">
            <div className="form-check">
              <input
                {...register("remember")}
                id="remember"
                name="remember"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="remember" className="form-check-label">
                Remember me
              </label>
            </div>
          </div> */}
          <div className="float-right">
            <Link to="/forgotpassword">
              <small>Forgot password?</small>
            </Link>
          </div>
        </div>
      </Form>
    </BoxContainerUnauthen>
  );
};
export default Login;
