import { DateService } from "@standard/services";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import DatePickerCustomHeader from "./DatePickerCustomHeader";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

export default function DatePicker({
  name = "",
  onChange = {},
  value = "",
  isRange = false,
  disabled = false,
  placeholder = "",
}) {
  const [selectedDate, setSelectedDate] = useState(isRange ? [] : null);

  useEffect(() => {
    let defaultValue = value;
    if (defaultValue) {
      if (isRange === false) {
        defaultValue = typeof value === "string" ? new Date(value) : value;
      } else {
        const [startDate, endDate] = value;
        defaultValue = [
          DateService.convertStringToDate(startDate),
          DateService.convertStringToDate(endDate),
        ];
      }
    }

    setSelectedDate(defaultValue);
  }, [value]);

  let params;

  if (isRange) {
    const [startDate, endDate] = selectedDate ? selectedDate : [null, null];
    params = {
      startDate: startDate,
      endDate: endDate,
    };
  } else {
    params = {
      selected: selectedDate,
    };
  }

  return (
    <div className="input-group mb-3">
      <ReactDatePicker
        {...params}
        onChange={(date) => {
          console.log("ReactDatePicker onChange", date);
          onChange({ name: name, value: date });
        }}
        className="form-control"
        disabled={disabled}
        id={name}
        name={name}
        placeholderText={placeholder}
        isClearable={!disabled}
        dateFormat="dd/MM/yyyy"
        selectsRange={isRange}
        renderCustomHeader={(customerHeaderParams) => (
          <DatePickerCustomHeader {...customerHeaderParams} />
        )}
      />
      <div className="input-group-append">
        <span className="input-group-text">
          <i className="fa fa-calendar" />
        </span>
      </div>
    </div>
  );
}