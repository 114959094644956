import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Button from "react-bootstrap/Button";
import ReactModal from "react-bootstrap/Modal";
import FormInput from "../FormInput/FormInput";
import "./Modal.css";

const Modal = (
  {
    btnClass = "",
    headerClass = "bg-primary",
    label,
    title,
    children,
    enableSave,
    onSave,
    onClosed,
    onValidate,
    onShown,
    fields = [],
    disabled,
    variant = "link",
    buttons = [],
    onChange = null, //fn
    loading = false,
  },
  ref
) => {
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState([]);

  useImperativeHandle(ref, () => ({
    close() {
      handleClose();
    },
    show() {
      handleShow();
    },
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isValid = () => {
    return new Promise((resolve, reject) => {
      let fieldErrors = [];

      fields
        .filter((f) => f.required === true)
        .map((m) => {
          if (!values[m.name]) {
            fieldErrors.push(`กรุณาระบุ${m.label}`);
          }
        });

      if (onValidate) {
        onValidate(values).catch(
          (customErrors) => (fieldErrors = fieldErrors.concat(customErrors))
        );
      }

      if (fieldErrors.length > 0) {
        return reject(fieldErrors);
      }

      return resolve(true);
    });
  };

  const onSaveModal = (e) => {
    e.preventDefault();
    isValid()
      .then(() => {
        setErrors([]);
        if (onSave) onSave(values).then((result) => setShow(result.show));
      })
      .catch((fieldErrors) => {
        console.log(
          "🚀 ~ file: index.js:65 ~ onSaveModal ~ fieldErrors:",
          fieldErrors
        );
        setErrors(fieldErrors);
      });
  };

  // useEffect(() => {
  //   const defaultValues = {};

  //   if (fields)
  //     fields.map((field) => (defaultValues[field.name] = field.value));

  //   setValues(defaultValues);
  // }, []);

  useEffect(() => {
    const defaultValues = {};

    if (fields)
      fields.map(
        (field) =>
          (defaultValues[field.name] = field.value || field.defaultValues)
      );

    /*
     * set default value
     */
    setValues(defaultValues);
  }, [JSON.stringify(fields)]);

  useEffect(() => {
    if (show === false && onClosed) onClosed();
    if (show === true && onShown) onShown();
  }, [show]);

  const onInputChange = ({ name, value }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    if (onChange) onChange({ name, value });
  };

  let className = [];
  if (variant === "link") {
    className.push("p-1 text-link");
  }

  return (
    <>
      <Button
        variant={variant}
        className={className.join(" ") + ` ${btnClass}`}
        onClick={handleShow}
        disabled={disabled}
        ref={ref}
      >
        {label}
      </Button>

      <ReactModal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby={label}
      >
        <ReactModal.Header className={headerClass}>
          <ReactModal.Title>{title ?? label}</ReactModal.Title>
          <a href="#" className="mt-2 mr-3 text-white" onClick={handleClose}>
            <i className="fa fa-times" style={{ fontSize: "1.5em" }}></i>
          </a>
        </ReactModal.Header>
        <ReactModal.Body>
          {loading === true && <div className="text-center">Loading...</div>}
          {loading === false && (
            <>
              {errors &&
                errors.map((error, errorIndex) => (
                  <div key={errorIndex} className="alert alert-danger">
                    {error}
                  </div>
                ))}
              <div className="row">
                {fields &&
                  fields.map((field, fieldIndex) => (
                    <FormInput
                      field={field}
                      value={field.value || field.defaultValues}
                      key={fieldIndex}
                      onChange={onInputChange}
                    />
                  ))}
              </div>
              {children}
            </>
          )}
        </ReactModal.Body>
        <ReactModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {enableSave && (
            <Button variant="primary" onClick={onSaveModal}>
              Save Changes
            </Button>
          )}

          {buttons.length > 0 &&
            buttons.map((button) => {
              return button;
            })}
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
};

export default forwardRef(Modal);
