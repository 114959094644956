function isNumeric(str) {
  if (typeof str === "number") return true;
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function convertMoneyToNumber(money) {
  if (money && typeof money === "string") {
    return Number(money.replace(/[^0-9.-]+/g, ""));
  }

  if (typeof money === "string" && money === "") return 0;

  return money;
}

function convertToMoney(_money, sign = null) {
  if (_money !== undefined && _money !== null && _money !== "") {
    let money = convertToDecimal(_money);

    return (
      money.toLocaleString(undefined, { maximumFractionDigits: 2 }) +
      (sign ? ` ${sign}` : "")
    );
  }

  return "";
}

function numberWithCommas(str) {
  if (typeof str !== "string") return "0";
  if (str === undefined || str === null) return "0";

  return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function convertToDecimal(number, defaultValue = 0) {
  if (typeof str === "number") return number;
  if (number === null || number === undefined || number === "" || isNaN(number))
    return defaultValue;

  return parseFloat(number);
}

function convertToInt(number, defaultValue = 0) {
  if (typeof str === "number") return number;
  if (number === null || number === undefined || number === "" || isNaN(number))
    return defaultValue;

  return parseInt(number);
}

export default {
  convertToMoney,
  convertMoneyToNumber,
  convertToDecimal,
  convertToInt,
  numberWithCommas,
  isNumeric,
};
