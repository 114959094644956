import TitleHeader from "@standard/components/TitleHeader";
import OrderForm from "./OrderForm";
import OrderTable from "./OrderTable";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Transaction";
const programName = "Order";
const url = "transaction/order";

export default function ({ history }) {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <OrderTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <OrderForm history={history} url={url} />
        </Route>
      </Switch>
    </>
  );
}
