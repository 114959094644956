import SearchResultData from "./SearchResultData";
import SearchResultHeader from "./SearchResultHeader";

const SearchResult = () => {
  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered table-hover sortable">
        <thead className="table-header">
          <SearchResultHeader />
        </thead>
        <tbody>
          <SearchResultData />
        </tbody>
        <tfoot>
          <SearchResultHeader isSortable={false} />
        </tfoot>
      </table>
    </div>
  );
};

export default SearchResult;
