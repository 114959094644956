import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AlertService,
  DateService,
  UtilsService,
  NumberService,
} from "@standard/services";
import axios from "@standard/axios";
import { FIELD_INPUT_TYPE } from "@standard/components/FormInput/FormInput";

const initialState = {
  config: {
    columns: [
      // {
      //   label: "column #1",
      //   align: "left",
      //   name: "col1",
      //   searchType: "string",
      //   sortable: false,
      //   search: {
      //     range: false,
      //     type: "string",
      //     datasource: [{ key: "", label: "" }],
      //   },
      // },
    ],
    url: "", // API Url

    customFilters: [],
    enableActive: false,
    enablePrint: false,
    enableInsert: true,
    enableTrash: true,
    enableView: false,
    enableEdit: true,
    enablePrintMultiple: false,
    enableTrashMultiple: false,
    enableCheckbox: false,
    enableAction: true,
    enableImport: false,

    // onPrintClick: [],
    buttons: [],
    // {
    //     variant: "primary",
    //     label: "Do #1",
    //     onClick: (selectedId) => console.log("do something #1", selectedId),
    // },
    buttonsRow: [], // { variant: "primary", label: "Do #2", onClick: (data) => console.log("do something #2", data), }

    exportURL: "",
    importURL: "",

    // event
    // onButtonRowRender: null,
    onPrint: null,
    onPrintMultiple: null,
    onBeforeBind: null,
  },
  searchCriteria: {
    orderBy: [],
    recordPerPage: 25,
    pageNumber: 1,
    keyword: "",
    filters: [],
  },
  data: [],
  totalRecord: 0,
  totalPage: 1,
  isLoading: false,
  selectedId: [],
  startRowRunning: 1,
  endRowRunning: 1,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const removeData = createAsyncThunk(
  "datatable/removeData",
  async (id, { getState, dispatch }) => {
    const state = getState();

    const { config } = state.dataTable;

    const promise = new Promise((resolve, reject) => {
      axios
        .delete(config.url + "/" + id, { data: { id } })
        .then((res) => {
          if (res.status === 200) {
            AlertService.done("Your data is deleted", "Success");
            dispatch(fetchData());
            resolve("success");
          } else {
            let errorMessage = "Can not delete this data";
            if (res.data.message) {
              errorMessage = res.data.message;
            }

            AlertService.error(errorMessage).then(() => reject(errorMessage));
          }
        })
        .catch((res) => {
          let errorMessage = "Can not delete this data";

          if (res.status === 400) {
            if (res.message instanceof Array) {
              errorMessage = res.message.join(", ");
            } else {
              errorMessage = res.message;
            }
          }

          AlertService.error(errorMessage).then(() => reject(errorMessage));
        });
    });

    return promise;
  }
);

export const importFile = createAsyncThunk(
  "datatable/importfile",
  async ({ importFile }, { dispatch, getState }) => {
    const state = getState();

    const { config } = state.dataTable;

    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        AlertService.done("Upload Complete")
          .then(() => resolve("success"))
          .then(() => dispatch(fetchData()));
      });
    });

    return promise;
  }
);

export const setActive = createAsyncThunk(
  "datatable/active",
  async ({ key, active }, { getState }) => {
    const state = getState();

    const { config } = state.dataTable;

    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        const { config } = state.dataTable;
        const url = `${config.url}/${key}/active`;
        axios
          .put(url, { active: active })
          .then((res) => console.log("active complete", res));
        resolve("success");
      });
    });

    return promise;
  }
);

export const fetchData = createAsyncThunk(
  "datatable/fetch",
  async (id, { getState }) => {
    const state = getState();

    let { config, searchCriteria } = state.dataTable;
    let params = { ...searchCriteria };
    params.orderBy = params.orderBy.join(",");
    params.filters = {};
    searchCriteria.filters.map((m) => {
      let newValue = m.value;
      if (newValue instanceof Date) {
        newValue = DateService.convertDateToServer(newValue);
      }

      if (newValue instanceof Array) {
        newValue = newValue.map((v) => {
          if (v instanceof Date) {
            return DateService.convertDateToServer(v);
          }

          return v;
        });
      }

      if (newValue && m.type === FIELD_INPUT_TYPE.NUMBER) {
        newValue = NumberService.convertToDecimal(newValue);
      }

      params.filters[m.columnName ?? m.name] = newValue;
    });

    const promise = new Promise((resolve, reject) => {
      axios.get(config.url, { params: params }).then((res) =>
        resolve({
          data: res.data,
          totalRecord: res.headers.totalrecord,
        })
      ).catch(err => {
        console.error(`DataTable Error =>`, err)

        if (err.message) AlertService.error(err.message).then(() => {
          reject(err);
        });
      }
      );
    });

    return promise;
  }
);

export const dataTableSlice = createSlice({
  name: "dataTable",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setConfig: (state, { payload }) => {
      state.config = { ...initialState, ...payload };
    },
    changePageNumber: (state, action) => {
      state.searchCriteria.pageNumber = action.payload;
    },
    setSearchCriteria: (state, { payload }) => {
      state.searchCriteria = { ...state.searchCriteria, ...payload };
    },
    clearSearchCriteria: (state, { payload }) => {
      state.searchCriteria = initialState.searchCriteria;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSelectedId: (state, { payload }) => {
      const index = state.selectedId.indexOf(payload);
      if (index === -1) {
        state.selectedId.push(payload);
      } else {
        state.selectedId.splice(index, 1);
      }
    },
    setSelectedAll: (state, { payload }) => {
      if (payload === false) {
        state.selectedId = [];
      } else {
        state.selectedId = state.data
          // .filter((f) => f.selectable !== false)
          .map((item) => item.key);
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (state.config.onBeforeBind) {
          state.data = state.config.onBeforeBind(payload.data);
        } else {
          state.data = payload.data;
        }
        state.totalRecord = NumberService.convertToInt(payload.totalRecord);
        state.totalPage = getTotalPage(state);
        state.startRowRunning = getStartRowRunning(state);
        state.endRowRunning = getEndRowRunning(state);
      })
      .addCase(fetchData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(removeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeData.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeData.fulfilled, (state, action) => { })
      .addCase(importFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setActive.pending, (state, { meta }) => {
        const key = meta.arg.key;
        const active = meta.arg.active;
        state.data = state.data.map((item) => {
          if (item.key === key) item.active = active;
          return item;
        });
      });
  },
});

const getTotalPage = ({ searchCriteria, totalRecord }) => {
  if (searchCriteria.recordPerPage === 0 || totalRecord === 0) {
    return 1;
  }

  return Math.ceil(totalRecord / searchCriteria.recordPerPage);
};

const getStartRowRunning = ({ searchCriteria }) => {
  let startRunning =
    (searchCriteria.pageNumber - 1) * searchCriteria.recordPerPage;

  return startRunning + 1;
};

const getEndRowRunning = ({ searchCriteria, startRowRunning, totalRecord }) => {
  let endRunning =
    parseInt(startRowRunning) + parseInt(searchCriteria.recordPerPage) - 1;

  if (searchCriteria.recordPerPage === 0 || endRunning > totalRecord)
    return totalRecord;
  else return endRunning;
};

export const {
  setConfig,
  changePageNumber,
  setLoading,
  setSearchCriteria,
  clearSearchCriteria,
  setSelectedId,
  setSelectedAll,
} = dataTableSlice.actions;

export const getURLSearchCriteria = (url) => (dispatch, getState) => {
  const searchCriteria = getState().dataTable.searchCriteria;

  let params = { ...searchCriteria };
  params.orderBy = params.orderBy.join(",");
  params.filters = {};
  searchCriteria.filters.map(
    (m) => (params.filters[m.columnName ?? m.name] = m.value)
  );

  params.filters = JSON.stringify(params.filters);
  if (params.keyword === undefined) params.keyword = "";

  const u = new URLSearchParams(params).toString();

  return `${url}?${u}`;
};

export default dataTableSlice.reducer;
