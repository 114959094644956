import Auth from "../pages/Auth";

export default [
  {
    link: "/",
    exact: true,
    component: Auth.Login,
  },
  {
    link: "/login",
    exact: true,
    component: Auth.Login,
  },
  {
    link: "/forgotpassword",
    component: Auth.ForgotPassword,
  },
  {
    link: "/changepassword/:token",
    component: Auth.ChangePassword,
  },
];
