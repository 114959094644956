import TableForm from '@standard/components/TableForm'
import { SUBSERVICE_TYPE } from 'data/constants';
import React, { useMemo } from 'react'


const SubservicePriceAirport = ({ values, onChange, disabled, label, subserviceLabel = { startPrice: "", pricePerway: "" } }) => {
    const fields = [
        { name: 'subserviceTypeLabel', label: 'Services', type: 'label' },
        { name: 'startPrice', label: subserviceLabel.startPrice, type: 'number', postfix: "Baht" },
        { name: 'pricePerway', label: subserviceLabel.pricePerway, type: 'number', postfix: "Baht" }
    ];

    return (<TableForm
        fields={fields}
        label={label}
        name=""
        values={values}
        disabled={disabled}
        enableInsert={false}
        enableTrash={false}
        onChange={onChange}
    />)
}

export default function SubservicePrices({ values, onChange, disabled }) {

    const limousine_transfer = useMemo(() => { return values.filter(f => f.values.subserviceType === SUBSERVICE_TYPE.LIMOUSINE_TRANSFER) }, [values]);
    const limousine_point_to_point = useMemo(() => { return values.filter(f => f.values.subserviceType === SUBSERVICE_TYPE.LIMOUSINE_POINT_TO_POINT) }, [values]);
    const disposal_outside = useMemo(() => { return values.filter(f => f.values.subserviceType === SUBSERVICE_TYPE.DISPOSAL_OUTSIDE) }, [values]);
    const disposal_within = useMemo(() => { return values.filter(f => f.values.subserviceType === SUBSERVICE_TYPE.DISPOSAL_WITHIN) }, [values]);

    const onTableFormChange = (tableValues) => {
        onChange(values.map(item => {
            const tableValue = tableValues.find(f => f.values.subserviceType === item.values.subserviceType);

            if (tableValue) {
                item.values.pricePerway = tableValue.values.pricePerway;
                item.values.startPrice = tableValue.values.startPrice;
            }

            return item;
        }));
    }

    return <>
        <div className="col-12">
            <SubservicePriceAirport
                onChange={onTableFormChange}
                values={limousine_transfer}
                disabled={disabled}
                label="Limousine Transfer"
                subserviceLabel={{ startPrice: "Price for <= 10KM", pricePerway: "Price for > 10KM" }}
            />
        </div>
        <div className="col-12">
            <SubservicePriceAirport
                onChange={onTableFormChange}
                values={limousine_point_to_point}
                disabled={disabled}
                label="Limousine Point to Point"
                subserviceLabel={{ startPrice: "Price for <= 10KM", pricePerway: "Price for > 10KM" }}
            />
        </div>
        <div className="col-12">
            <SubservicePriceAirport
                onChange={onTableFormChange}
                values={disposal_within}
                disabled={disabled}
                label="Disposal Within"
                subserviceLabel={{ startPrice: "Price for <= 6 Hours", pricePerway: "Price for > 6 Hours" }}
            />
        </div>
        <div className="col-12">
            <SubservicePriceAirport
                onChange={onTableFormChange}
                values={disposal_outside}
                disabled={disabled}
                label="Disposal Outside"
                subserviceLabel={{ startPrice: "Price for <= 10 Hours", pricePerway: "Price for > 10 Hours" }}
            />
        </div>
    </>
}
