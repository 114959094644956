import React from 'react'
import FormInput from '@standard/components/FormInput/FormInput';

export default function OrderFormVehicle(props) {
    const fields = [
        { name: "name", label: "Name", type: "label", col: 6, value: props.vehicleName },
        { name: "maxPassengers", label: "Max Passengers", type: "label", col: 6, value: props.vehicleMaxPassengers },
        { name: "type", label: "Type", type: "label", col: 6, value: props.vehicleType },
        { name: "luggageAllowance", label: "Luggage Allowance", type: "label", col: 6, value: props.vehicleLuggageAllowance },
    ];

    return (
        <div className="col-12 mt-2">
            <div className="card">
                <div className="card-header bg-warning">
                    <h3>Vehicle Info</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        {props.vehicleThumbnailAssetPreview && <div className="col-12 text-center">
                            <img src={props.vehicleThumbnailAssetPreview.url} className="img-thumbnail" />
                        </div>}
                        {fields.map(field => <FormInput key={field.name} field={field} value={field.value ?? ""} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}
