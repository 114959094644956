import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import SearchCriteriaFilter from "./SearchCriteriaFilter";
import SearchCriteriaFilterBy from "./SearchCriteriaFilterBy";
import { memo, useState, useRef, useEffect } from "react";

const $ = window.$;
const _ = require("lodash");

const SearchCriteria = () => {
  const dispatch = useDispatch();
  const [toggleAdvanceSearch, setToggleAdvanceSearch] = useState(false);
  const searchForm = useRef(null);

  const onSubmitForm = (data) => {
    dispatch(setSearchCriteria({ keyword: data.keyword }));
    setToggleAdvanceSearch(false);
    // history.push(`?keyword=${data.keyword}`);
  };

  const { control, handleSubmit, setValue } = useForm({
    // defaultValues: { keyword: searchCriteria.keyword },
  });

  useEffect(() => {
    // Close advance search
    document
      .getElementsByTagName("body")[0]
      .addEventListener("keydown", (e) => {
        if (e.keyCode === 27 || e.which === 27) setToggleAdvanceSearch(false);
      });

    // const autoCompleteJS = new autoComplete({
    //   selector: "#keyword",
    //   placeHolder: "Search...",
    //   data: {
    //     src: [
    //       "Sauce - Thousand Island",
    //       "Wild Boar - Tenderloin",
    //       "Goat - Whole Cut",
    //     ],
    //     cache: true,
    //   },
    //   resultsList: {
    //     element: (list, data) => {
    //       if (!data.results.length) {
    //         // Create "No Results" message element
    //         const message = document.createElement("div");
    //         // Add class to the created element
    //         message.setAttribute("class", "no_result");
    //         // Add message text content
    //         message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
    //         // Append message element to the results list
    //         list.prepend(message);
    //       }
    //     },
    //     noResults: true,
    //   },
    //   resultItem: {
    //     highlight: true,
    //   },
    //   events: {
    //     input: {
    //       selection: (event) => {
    //         const selection = event.detail.selection.value;
    //         autoCompleteJS.input.value = selection;
    //       },
    //     },
    //   },
    // });
    // var debounced = _.debounce(() => searchForm.current.submit(), 1000);
    // $(searchForm.current)
    //   .autocomplete({
    //     minLength: 3,
    //     source: (request, response) => {
    //       const params = {
    //         autocomplete: 1,
    //         keyword: request.term,
    //       };

    //       axios.get(config.url, { params }).then((res) => {
    //         const results = res.data.data;
    //         var wording = results.reduce((prev, item) => {
    //           item.value.map((item_value) => {
    //             if (
    //               prev.indexOf(item_value) === -1 &&
    //               item_value.indexOf(request.term) >= 0
    //             ) {
    //               prev.push(item_value);
    //             }
    //           });

    //           return prev;
    //         }, []);

    //         response(wording);
    //       });
    //     },
    //     change: (e, ui) => {
    //       if (ui.item) {
    //         const value =
    //           ui.item.value instanceof Object
    //             ? ui.item.value[0]
    //             : ui.item.value;
    //         // this.keywordChange(value);
    //       }
    //     },
    //     select: (e, ui) => {
    //       console.log("Selected: " + ui.item.value + " aka " + ui.item.id);
    //       if (ui.item) {
    //         const value =
    //           ui.item.value instanceof Object
    //             ? ui.item.value[0]
    //             : ui.item.value;

    //         // this.keywordChange(value);
    //         // this.search();
    //       }
    //     },
    //   })
    //   .off("keyup")
    //   .on("keyup", debounced);
  }, []);

  return (
    <div className="search-criteria-container">
      <Form onSubmit={handleSubmit(onSubmitForm)} ref={searchForm}>
        <div className="input-group mb-0 search-criteria">
          <span className="input-group-prepend">
            <Button type="submit" variant="primary">
              <i className="fa fa-search"></i>
            </Button>
          </span>
          <Controller
            name="keyword"
            control={control}
            render={({ field }) => (
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                name="keyword"
                id="keyword"
                // onKeyUp={(e) => debounced()}
                dir="ltr"
                spellCheck={false}
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                tabIndex="1"
                {...field}
              />
            )}
          />
          <div className="input-group-append">
            <span
              className="input-group-text"
              onClick={() => setToggleAdvanceSearch(!toggleAdvanceSearch)}
            >
              <div className="toggle-advance-search">
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </div>
            </span>
          </div>
        </div>
        <SearchCriteriaFilterBy />
      </Form>
      <SearchCriteriaFilter
        toggleAdvanceSearch={toggleAdvanceSearch}
        onSetToggleAdvanceSearch={setToggleAdvanceSearch}
      />
    </div>
  );
};

export default memo(SearchCriteria);
