import { SERVICE_TYPE } from "./constants";

const data = [
    { key: SERVICE_TYPE.LIMOUSINE, label: "Limousine" },
    { key: SERVICE_TYPE.DISPOSAL, label: "Disposal" },
    { key: SERVICE_TYPE.AIRPORT, label: "Airport" },
];

export default data;

export const ServiceTypeComponent = ({ value }) => {
    const label = data.find((f) => f.key === value)?.label ?? "";
    let badgeType = "";
    switch (value) {
        case SERVICE_TYPE.LIMOUSINE:
            badgeType = "warning";
            break;
        case SERVICE_TYPE.DISPOSAL:
            badgeType = "primary";
            break;
        case SERVICE_TYPE.AIRPORT:
            badgeType = "danger";
            break;
    }

    return <div className={`badge badge-${badgeType}`}>{label}</div>;
};
