import DataTable from "@standard/components/DataTable";
import MemberSocial from "./MemberSocial";

const columns = [
  {
    label: "Name",
    name: "name",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Email",
    name: "email",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Telephone",
    name: "telephone",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Socials",
    name: "socials",
    align: "center",
    sortable: false,
  },
];

const config = {
  enableInsert: false,
  enableActive: true,
  enableEdit: false,
  enableTrash: false,
  enableView: true,
  onBeforeBind: (data) => {
    return data.map((item) => {
      const { socials } = item.values;

      item.values.socials = <MemberSocial values={socials} />

      return item;
    });
  },
};

export default ({ url }) => {
  return <DataTable columns={columns} url={url} {...config} />;
};
