import axios from "axios";
import cookie from "@standard/services/cookie";
import { STORAGE_NAME } from "@standard/constants";
import { AuthService } from "./services";

const config = {
  baseURL: process.env.REACT_APP_API_URI,
  withCredentials: true,
  params: {},
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
};

const instance = axios.create(config);

// const user = JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER));

// if (user) {
//   const token = cookie.getToken();
//   instance.defaults.headers.common["Authorization"] = "Bearer " + token;
// }

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log("axios before loading", config);
    return config;
  },
  function (error) {
    // console.log("axios error before loading", config);
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // store.dispath(setLoading(false));
    // console.log("axios after loading", response);
    return response;
  },
  function (error) {
    if (error.response === undefined) {
      return Promise.reject({
        status: 500,
        success: false,
        message: ["Unexpected error, please contact admin"],
      });
    }

    const { status, data } = error.response;
    if (status === 401 || status === 403) {
      AuthService.doLogout();
      console.log("unauthorized, logging out ...");
    } else if (status === 404) {
      // redirect to 404
      console.log("Response page not found");
    } else if (status === 500) {
    } else if (status === 400) {
    }

    return Promise.reject(data);
  }
);

export default instance;
