import axios from "@standard/axios";

export const login = (username, password, remember) => {
  const promise = new Promise((resolve, reject) => {
    axios
      .post("auth/login", { username, password, remember })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err.message));
  });

  return promise;
};

export const logout = () => axios.post(`auth/logout`);

export const changePasswordFromResetPassword = ({ token, newPassword }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`auth/changepasswordfromresetpassword/${token}`, {
        newPassword: newPassword,
      })
      .then((res) => resolve({ data: res.data }))
      .catch((err) => reject(err.message));
  });

export const forgetPassword = async (email) =>
  new Promise((resolve, reject) => {
    axios
      .post("auth/forgetPassword", { email })
      .then((res) => {
        if (res.status === 200) resolve({});
      })
      .catch((err) => reject(err.message));
  });

export const changePassword = ({ oldPassword, newPassword, confirmPassword }) =>
  new Promise((resolve, reject) => {
    axios
      .put(`auth/changepassword`, {
        oldPassword,
        newPassword,
        confirmPassword,
      })
      .then((res) => resolve({ data: res.data }))
      .catch((err) => reject(err.message));
  });

export const updateCurrentUserProfile = (data) =>
  new Promise((resolve, reject) => {
    axios
      .put(`auth/profile`, data)
      .then((res) => resolve({ data: res.data }))
      .catch((err) => reject(err.message));
  });
