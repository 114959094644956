import React from 'react'
import FormInput from '@standard/components/FormInput/FormInput';

export default function OrderFormCustomerInfo(props) {
    const fields = [
        { name: "name", label: "Your Name", type: "label", col: 6, value: props.name },
        { name: "telephone", label: "Phone Number", type: "label", col: 6, value: props.telephone },
        { name: "email", label: "Email", type: "label", col: 12, value: props.email },
        { name: "flightNo", label: "Flight Number", type: "label", col: 6, value: props.flightNo },
        { name: "hours", label: "Hours", type: "label", col: 6, value: props.hours },
        { name: "weChatId", label: "We Chat", type: "label", col: 6, value: props.weChatId },
        { name: "whatAppId", label: "What App", type: "label", col: 6, value: props.whatAppId },
        { name: "lineId", label: "Line", type: "label", col: 12, value: props.lineId },
        { name: "numberOfPassenger", label: "Number of passenger", type: "label", col: 6, value: props.numberOfPassenger },
        { name: "numberOfluggage", label: "Number of luggage", type: "label", col: 6, value: props.numberOfluggage },
        { name: "pickupDate", label: "Service Date", type: "label", col: 6, value: props.pickupDate },
        { name: "pickupTime", label: "Service Time", type: "label", col: 6, value: props.pickupTime },
        { name: "message", label: "Message", type: "label", col: 12, value: props.message },
        { name: "itinerary", label: "Itinerary Plan", type: "label", col: 12, value: props.itinerary },
    ];

    return (
        <div className="col-12 mt-2">
            <div className="card">
                <div className="card-header bg-primary">
                    <h3>Customer Info</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        {fields.map(field => <FormInput key={field.name} field={field} value={field.value ?? ""} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}
