export const MEMBER_ROLE = {
  GUEST: "guest",
  MEMBER: "member",
  DEALER: "dealer",
  PARTNER: "partner",
};

export default [
  { key: MEMBER_ROLE.GUEST, label: "Guest" },
  { key: MEMBER_ROLE.MEMBER, label: "Member" },
  { key: MEMBER_ROLE.PARTNER, label: "Partner" },
  { key: MEMBER_ROLE.DEALER, label: "Dealer" },
];
