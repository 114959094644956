import axios from "@standard/axios";

export const completeOrder = (id, remark) => {
    const promise = new Promise((resolve, reject) => {
        axios
            .put(`transaction/order/complete/${id}`, { remark })
            .then((res) => resolve(res.data))
            .catch((errors) => reject(errors));
    });

    return promise;
};
export const cancelOrder = (id, remark) => {
    const promise = new Promise((resolve, reject) => {
        axios
            .put(`transaction/order/cancel/${id}`, { remark })
            .then((res) => resolve(res.data))
            .catch((errors) => reject(errors));
    });

    return promise;
};
