import DataTable from "@standard/components/DataTable";

const columns = [
  {
    label: "Sequence",
    name: "sequence",
    align: "right",
    sortable: true,
  },
  {
    label: "Name",
    name: "name",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Luggage",
    name: "luggageAllowance",
    align: "right",
    sortable: true,
  },
  {
    label: "Passenger",
    name: "maxPassengers",
    align: "right",
    sortable: true,
  },
  {
    label: "Type",
    name: "type",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
];

const config = {
  enableActive: true,
  enableEdit: true,
  enableTrash: true,
  onBeforeBind: (data) => {
    return data.map((item) => {
      return item;
    });
  },
};

export default ({ url }) => {
  return <DataTable columns={columns} url={url} {...config} />;
};
