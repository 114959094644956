import { Tabs, Tab, Badge } from "react-bootstrap";
import React, {
  useEffect,
  useState,
  memo,
  useCallback,
  useContext,
} from "react";
import { resetConfigure } from "axios-hooks";
import { UtilsService } from "@standard/services";
import FormInput from "../FormInput/FormInput";
import { Controller, useForm } from "react-hook-form";
import { DataFormContext } from "./DataForm";

const DataFormInput = ({ field, register, onChange }) => {
  const onChangeValue = useCallback(
    (value) => {
      onChange(value);
    },
    [field]
  );

  return (
    <FormInput
      field={field}
      {...register(field.name, {
        required: field.required,
      })}
      onChange={onChangeValue}
    />
  );
};

const DataFormContent = ({ fields, tabs = [], refForm, enableTabs }) => {
  const getField = (field, index) => {
    if (field.type === "custom") return field.control;

    const rules = {
      required: field.required ? `${field.label} is required` : false,
    };

    if (field.validate) {
      rules.validate = field.validate;
    }

    return (
      <Controller
        key={field.name}
        control={refForm.control}
        name={field.name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <FormInput
            field={field}
            value={value}
            onChange={({ value }) => onChange(value)}
          />
        )}
      />
    );
  };

  return (
    <>
      {enableTabs === true && (
        <Tabs
          defaultActiveKey={tabs[0].name}
          id="tabs"
          className="mb-3"
          onSelect={(tabName) => { }}
        >
          {tabs.map((tab) => {
            return (
              <Tab eventKey={tab.name} key={tab.name} title={tab.label}>
                <div className="row">{tab.fields.map(getField)}</div>
              </Tab>
            );
          })}
        </Tabs>
      )}

      {fields && <div className="row">{fields.map(getField)}</div>}
    </>
  );
};

export default memo(DataFormContent);
