import { forwardRef } from "react";
import FileInput from "../FileInput";

const FormInputFile = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { multiple, extension, name, disabled } = field;

  return (
    <FileInput
      ref={ref}
      multiple={multiple}
      extension={extension}
      onChange={({ value }) => {
        onChange({ name, value });
      }}
      name={name}
      value={fieldValue}
      disabled={disabled}
    />
  );
});

export default FormInputFile;
