import TitleHeader from "@standard/components/TitleHeader";
import MemberForm from "./MemberForm";
import MemberTable from "./MemberTable";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const programType = "Master";
const programName = "Member";
const url = "master/member";

export default function User({ history }) {
  const { path } = useRouteMatch();

  return (
    <>
      <TitleHeader programName={programName} programType={programType} />
      <Switch>
        <Route exact path={path}>
          <MemberTable url={url} />
        </Route>
        <Route path={`${path}/:action/:id?`}>
          <MemberForm history={history} url={url} />
        </Route>
      </Switch>
    </>
  );
}
