import Dashboard from "../pages/Dashboard";
import Master from "../pages/Master";
import { PROGRAM_CODE } from "data/constants";
import auth from "@standard/services/auth";
import Transaction from "pages/Transaction";

export const Menues = [
  {
    label: "Master",
    iconClass: "fa fa-users",
    child: [
      {
        label: "User",
        link: "/master/user",
        component: Master.User,
        // code: PROGRAM_CODE.MASTER_USER,
      },
      {
        label: "Setting",
        link: "/master/setting",
        component: Master.Setting,
      },
      {
        label: "Vehicle",
        link: "/master/vehicle",
        component: Master.Vehicle,
      },
      {
        label: "Member",
        link: "/master/member",
        component: Master.Member,
      },
    ],
  },
  {
    label: "Tranaction",
    iconClass: "fa fa-users",
    child: [
      {
        label: "Order",
        link: "/transaction/order",
        component: Transaction.Order,
      },
      {
        label: "Payment",
        link: "/transaction/payment",
        component: Transaction.Payment,
      },
    ],
  },
];

function getMenuByProfile() {
  const menuProfiles = auth.getMenuProfiles();

  if (menuProfiles)
    return Menues.map((f) => {
      // if (f.child) {
      //   return {
      //     ...f,
      //     child: f.child.filter((child) => menuProfiles.includes(child.code)),
      //   };
      // }

      // if (menuProfiles.includes(f.code)) return f;

      // return null;

      return f;
    }).filter((f) => {
      if (f === null) return false;
      if (f.child === undefined || f.child.length > 0) return true;

      return false;
    });

  return [];
}

export default [
  {
    label: "Dashboard",
    iconClass: "fa fa-dashboard",
    link: "/",
    exact: true,
    component: Dashboard,
  },
  ...getMenuByProfile(),
];
