import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams } from "react-router-dom";
import { useState, memo } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { DatasourceAPI, UserAPI } from "api";
import { AlertService, DateService, ErrorService, FormService, NumberService } from "@standard/services";
import { useEffect } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { MEMBER_ROLE } from "data/memberRole";
import { Controller, useForm } from "react-hook-form";
import FormInput from "@standard/components/FormInput/FormInput";
import { MemberRoleData } from "data";
import MemberOrder from "./MemberOrder";
import { LinkButton } from "@standard/components/Button";
import { ServiceTypeComponent } from "data/serviceType";
import { SubserviceTypeComponent } from "data/subserviceType";
import { PaymentStatusComponent } from "data/paymentStatus";
import { OrderStatusComponent } from "data/orderStatus";
import MemberSocial from "./MemberSocial";

const onResetPassword = (id) => {
  AlertService.confirm("Do you want to reset password ?").then((res) => {
    if (res) {
      UserAPI.resetPassword(id).then(() =>
        AlertService.done(
          "Link for reseting password was sending to your email, please check your email"
        )
      );
    }
  });
};

const onUnlock = (id) => {
  AlertService.confirm("Do you want to unlocked ?").then((res) => {
    if (res) {
      UserAPI.unlocked(id).then(() =>
        AlertService.done("This user account was changed status to Active")
      );
    }
  });
};

export default function MemberForm({ history, url }) {
  const { id, action } = useParams();
  const [buttons, setButtons] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const disabled = true;

  const defaultValues = {
    id: id,
    username: "",
    name: "",
    email: "",
    address: "",
    telephone: "",
    role: MEMBER_ROLE.MEMBER,
    weChatId: "",
    whatAppId: "",
    lineId: "",
    active: true,
  };

  const reactHookForm = useForm({ defaultValues });

  useEffect(() => {
    if (id !== undefined) {
      retrieveData(id);
    }
  }, []);

  const retrieveData = (id) => {
    FormService.getRequest(url, id).then((result) => {
      reactHookForm.reset(result);
      setOrders(result.orders.map(order => {

        order.values.documentNo = <LinkButton label={order.values.documentNo} url={`/transaction/order/view/${order.values._id}`} />;
        order.values.createdAt = DateService.convertDateTimeToString(order.values.createdAt);
        order.values.serviceType = <ServiceTypeComponent value={order.values.serviceType} />;
        order.values.subserviceType = <SubserviceTypeComponent value={order.values.subserviceType} />;
        order.values.paymentStatus = <PaymentStatusComponent value={order.values.paymentStatus} />;
        order.values.status = <OrderStatusComponent value={order.values.status} />;
        order.values.netLabel = NumberService.convertToMoney(order.values.net);

        return order;
      }));
      setIsLoading(false);
    });
  };

  const onSubmit = (newData) => {

  };

  let fields = [
    {
      label: "Username",
      name: "username",
      type: id === undefined ? "text" : "label",
      required: true,
      disabled: disabled,
    },
  ];

  if (id === undefined) {
    fields.push({
      label: "Password",
      name: "password",
      type: "password",
      required: true,
      col: 6,
      disabled: disabled,
    });

    fields.push({
      label: "Confirm password",
      name: "confirmPassword",
      type: "password",
      required: true,
      validate: {
        mustEqualToPassword: (val) => {
          if (val !== reactHookForm.getValues("password"))
            return "Confirm Password must be equal to New Password.";

          return true;
        },
      },
      col: 6,
      disabled: disabled,
    });
  }

  fields = fields.concat([
    {
      label: "Name",
      name: "name",
      type: "text",
      required: false,
      col: 6,
      disabled: disabled,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
      col: 6,
      disabled: disabled,
    },
    {
      label: "Telephone",
      name: "telephone",
      type: "text",
      required: false,
      col: 12,
      disabled: disabled,
    },
    {
      label: "We Chat",
      name: "weChatId",
      type: "text",
      required: false,
      col: 4,
      disabled: disabled,
    },
    {
      label: "What App",
      name: "whatAppId",
      type: "text",
      required: false,
      col: 4,
      disabled: disabled,
    },
    {
      label: "Line",
      name: "lineId",
      type: "text",
      required: false,
      col: 4,
      disabled: disabled,
    },
    {
      label: "Active",
      name: "active",
      type: "switch",
      disabled: disabled,
    },
    {
      type: "custom",
      control: <div className="col-12" key="memberorder">
        <MemberOrder orders={orders} />
      </div>
    },
  ]);

  const fieldErrors = ErrorService.convertToArray(
    reactHookForm.formState.errors
  );

  return (
    <BoxContainer loading={isLoading} errors={errors.concat(fieldErrors)}>
      <DataForm
        refForm={reactHookForm}
        leftPanel={<MemberSocial values={reactHookForm.getValues("socials")} />}
        onSubmit={onSubmit}
        fields={fields}
        url={url}
        buttons={buttons}
        enableSave={false}
      ></DataForm>
    </BoxContainer>
  );
}
