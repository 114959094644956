import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AlertService, AuthService } from "@standard/services";
import { BoxContainerUnauthen } from "@standard/components/BoxContainer";
import { useState, useEffect } from "react";
import _ from "lodash";

const defaultValues = {
  email: "",
};

const ForgotPassword = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const { register, formState, handleSubmit } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    AuthService.forgetPassword(data.email)
      .then(() => {
        setIsLoading(false);
        AlertService.done(
          "Your password was reset, please check your email"
        ).then(() => history.push("login"));
      })
      .catch((error) => {
        setIsLoading(false);
        setErrors(error);
      });
  };

  const _errors = [];
  for (var i in formState.errors) _errors.push(formState.errors[i].message);

  return (
    <BoxContainerUnauthen
      isLoading={isLoading}
      errors={_errors.concat(errors)}
      title="Forgot password"
      description="Enter your email address and your password will be reset and emailed
    to you."
      onClearError={() => setErrors([])}
    >
      <form className="m-t" id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            name="email"
            {...register("email", { required: "Please enter your email." })}
          />
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary block full-width m-b"
          >
            Send new password
          </button>
        </div>
        <div className="form-group float-right">
          <small>
            <Link to="/login">Go to login</Link>
          </small>
        </div>
      </form>
    </BoxContainerUnauthen>
  );
};

export default ForgotPassword;
