import { forwardRef } from "react";

const FormInputTextArea = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { name, label, disabled } = field;

  return (
    <textarea
      rows={5}
      className="form-control"
      name={name}
      placeholder={label}
      value={fieldValue}
      ref={ref}
      disabled={disabled}
      onChange={(e) => onChange({ name, value: e.target.value })}
    />
  );
});

export default FormInputTextArea;
