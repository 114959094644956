import { forwardRef } from "react";

const FormInputText = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { name, type, label, disabled, datasource } = field;

  return (
    <>
      <input
        ref={ref}
        className="form-control"
        name={name}
        type={type}
        placeholder={label}
        value={fieldValue}
        disabled={disabled}
        onChange={(e) => onChange({ name, value: e.target.value })}
      />
      {datasource && (
        <div className="mt-1">
          {datasource.map((item) => (
            <button
              key={item.key}
              className="btn btn-info mx-1 btn-small btn-sm"
              type="button"
              onClick={() => {
                onChange({ name, value: item.label });
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </>
  );
});

export default FormInputText;
