import { memo } from "react";
import DataFormButtonGroup from "./DataFormButtonGroup";

const DataFormHeader = ({ leftPanel }) => {
  return (
    <div className="form-group row">
      <div className="col-12 col-md-4 text-left">{leftPanel}</div>
      <DataFormButtonGroup />
    </div>
  );
};

export default memo(DataFormHeader);
