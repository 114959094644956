const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: "9999",
        top: "0",
        paddingTop: " 10%",
        left: "0",
        verticalAlign: "middle",
        textAlign: "center",
      }}
    >
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-bounce1"></div>
        <div className="sk-double-bounce2"></div>
      </div>
    </div>
  );
};

export default Loader;
