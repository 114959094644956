import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams } from "react-router-dom";
import { useState } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { AlertService, ErrorService, FormService } from "@standard/services";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import SubservicePrices from "./SubservicePrices";
import { SubserviceTypeData } from "data";
import { SUBSERVICE_TYPE } from "data/constants";

export default ({ history, url }) => {
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [subservicePrices, setSubservicePrices] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const defaultValues = {
    id: id,
    name: "",
    maxPassengers: "",
    type: "",
    luggageAllowance: "",
    description: "",
    thumbnailAsset: {},
    thumbnailAssetPreview: {},
    imageAssets: [],
    imageAssetsPreview: [],
    sequence: "",
    active: true,
  };

  const reactHookForm = useForm({ defaultValues });

  useEffect(() => {
    if (id !== undefined) {
      retrieveData(id);
    } else {
      setSubservicePrices(SubserviceTypeData.map(subserviceType => {
        return {
          key: null,
          values: {
            subserviceType: subserviceType.key,
            subserviceTypeLabel: subserviceType.label,
            startPrice: "",
            pricePerway: "",
          }
        }
      }))

      setIsLoading(false);
    }
  }, []);

  const retrieveData = (id) => {
    FormService.getRequest(url, id).then((result) => {
      reactHookForm.reset(result);
      setSubservicePrices(result.subservicePrices.map(item => {
        item.values.subserviceTypeLabel = SubserviceTypeData.find(f => f.key === item.values.subserviceType)?.label ?? "-";
        return item;
      }));
      setIsLoading(false);
    });
  };

  const saveData = (newData) => {
    setIsLoading(true);

    const params = { ...newData, id: id };
    params.subservicePrices = subservicePrices.map(item => {
      return { ...item.values }
    });

    params.subservicePrices = JSON.stringify(params.subservicePrices);

    FormService.submitRequestWithFile(url, params)
      .then(() => {
        setIsLoading(false);
        setErrors([]);
        history.push(`/${url}`);
      })
      .catch((err) => {
        console.log("Catch error", err);
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = (newData) => {
    AlertService.confirm("Do you want to save data").then((res) => {
      if (res === true) saveData(newData);
    });
  };

  let fields = [
    {
      label: "Name",
      name: "name",
      type: "text",
      required: true,
    },

    {
      label: "Max Passengers",
      name: "maxPassengers",
      type: "number",
      required: true,
      col: 6,
    },
    {
      label: "Luggage Allowance",
      name: "luggageAllowance",
      type: "number",
      required: true,
      col: 6,
    },
    {
      label: "Type",
      name: "type",
      type: "text",
      required: true,
      col: 12,
    },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      required: false,
      col: 12,
    },
    {
      name: "thumbnailAsset",
      label: "Thumbnail",
      type: "file",
      extension: "image",
    },
    { name: "thumbnailAssetPreview", type: "filePreview" },
    {
      name: "imageAssets",
      label: "Images",
      type: "file",
      multiple: true,
      extension: "image",
    },
    { name: "imageAssetsPreview", type: "filePreview", disabled: false },
    {
      name: "subservicePrices", type: "custom", control: <SubservicePrices key="subservicePrices" values={subservicePrices} onChange={newSubServicePrices => setSubservicePrices(newSubServicePrices)} disabled={disabled} />
    },
    {
      label: "Sequence",
      name: "sequence",
      type: "number",
      decimal: 2,
    },
    {
      label: "Active",
      name: "active",
      type: "switch",
    },
  ];

  const fieldErrors = ErrorService.convertToArray(
    reactHookForm.formState.errors
  );

  return (
    <BoxContainer loading={isLoading} errors={errors.concat(fieldErrors)}>
      <DataForm
        refForm={reactHookForm}
        onSubmit={onSubmit}
        fields={fields}
        url={url}
      ></DataForm>
    </BoxContainer>
  );
};
