import { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./App.css";
import {
  LayoutWithUnauthen,
  LayoutWithAuthen,
} from "@standard/components/Containers/Layout";
import { AuthService } from "@standard/services";
import { PrivateRoute } from "@standard/routes";
import { history } from "@standard/history";
import { menuesAuthen, menuesUnauthen } from "configs";
import Auth from "pages/Auth";
import NotFound from "pages/NotFound";

const App = () => {
  // const [currentUser, setCurrentUser] = useState({});
  // useEffect(() => {
  //   AuthService.currentUser.subscribe((value) => {
  //     setCurrentUser(value);
  //   });
  // }, []);

  var container;
  if (AuthService.isAuth()) {
    const menues = [];

    menuesAuthen
      .filter((f) => AuthService.isInRoles(f.roles))
      .map((route, index) => {
        if (route.link) {
          menues.push(
            <PrivateRoute
              key={route.link}
              path={route.link}
              exact={route.exact}
              component={route.component}
            />
          );
        } else if (route.child !== undefined && route.child.length > 0) {
          route.child.map((child_route, child_route_index) =>
            menues.push(
              <PrivateRoute
                key={child_route.link}
                path={child_route.link}
                exact={false}
                component={child_route.component}
              />
            )
          );
        }
      });

    menues.push(
      <PrivateRoute
        key={"profile"}
        path={"/profile"}
        exact={true}
        component={Auth.Profile}
      />
    );

    menues.push(
      <PrivateRoute
        key={"changepassword"}
        path={"/profile/changepassword"}
        exact={true}
        component={Auth.ChangePasswordWithAuth}
      />
    );

    container = (
      <LayoutWithAuthen>
        <Switch>
          {menues}
          <Route path="*" component={NotFound} />
        </Switch>
      </LayoutWithAuthen>
    );
  } else {
    const menues = menuesUnauthen.map((route, index) => (
      <Route
        key={index}
        path={route.link}
        exact={route.exact}
        component={route.component}
      />
    ));

    container = (
      <LayoutWithUnauthen>
        <Switch>
          {menues}
          <Route path="*" component={NotFound} />
        </Switch>
      </LayoutWithUnauthen>
    );
  }

  return <Router history={history}>{container}</Router>;
};
export default App;
