import { BehaviorSubject } from "rxjs";
import Alert from "./alert";
import { STORAGE_NAME } from "@standard/constants";
import { AuthAPI } from "api";
import cookie from "./cookie";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER))
);

function isAuth() {
  const currentUser = getCurrentUser();

  if (currentUser === null) return false;

  return true;
}

function changePasswordFromResetPassword({
  token,
  newPassword,
  confirmPassword,
}) {
  var promise = AuthAPI.changePasswordFromResetPassword({
    token,
    newPassword,
    confirmPassword,
  });
  return promise;
}

function forgetPassword(email) {
  var promise = AuthAPI.forgetPassword(email);
  return promise;
}

function login({ username, password, remember }) {
  var promise = new Promise(function (resolve, reject) {
    AuthAPI.login(username, password, remember)
      .then((res) => {
        const currentUser = res.user;
        const token = res.token;
        const menuProfiles = res.menuProfiles;
        setCurrentUser(currentUser, token, menuProfiles);
        resolve(currentUser);
      })
      .catch((error) => reject(error));
  });
  return promise;
}

function logout() {
  Alert.confirm("คุณต้องการออกจากระบบหรือไม่", "Logout").then((res) => {
    if (res === true) doLogout();
  });
}

function doLogout() {
  AuthAPI.logout().then(() => {
    localStorage.removeItem(STORAGE_NAME.CURRENT_USER);
    localStorage.removeItem(STORAGE_NAME.MENU_PROFILE);
    localStorage.clear();
    cookie.removeToken();
    currentUserSubject.next(null);
    window.location.href = "/";
  });
}

function getCurrentUser() {
  return currentUserSubject.value;
}

function getMenuProfiles() {
  const menuProfile = localStorage.getItem(STORAGE_NAME.MENU_PROFILE);

  return menuProfile;
}

function setCurrentUser(currentUser, token = null, menuProfiles = []) {
  localStorage.setItem(STORAGE_NAME.CURRENT_USER, JSON.stringify(currentUser));
  localStorage.setItem(STORAGE_NAME.MENU_PROFILE, JSON.stringify(menuProfiles));
  currentUserSubject.next(currentUser);
  if (token !== null) cookie.setToken(token);
}

const isInRoles = (roles) => {
  if (roles === undefined) return true;

  const user = getCurrentUser();
  const userRoles = user.roles;

  for (const userRole of userRoles) {
    if (roles.indexOf(userRole) !== -1) return true;
  }

  return false;
};

const auth = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  getCurrentUser,
  doLogout,
  isAuth,
  setCurrentUser,
  forgetPassword,
  changePasswordFromResetPassword,
  isInRoles,
  getMenuProfiles,
};

export default auth;
