const getErrorMessage = (type, fieldName) => {
  switch (type) {
    case "required":
      return `${fieldName} is required`;
    default:
      return `${fieldName} is error`;
  }
};

const error = {
  convertToArray: (errorObject) => {
    // errorObject = JSON type from useForm (formState.errors)
    const errors = [];
    for (var i in errorObject) {
      const error = errorObject[i];
      let errorMessage = "";

      if (error.message) {
        errorMessage = error.message;
      } else {
        errorMessage = getErrorMessage(error.type, error.ref.name);
      }

      errors.push(errorMessage);
    }

    return errors;
  },
  getErrorMessages: (errorObject) => {
    if (errorObject.message) {
      return [errorObject.message];
    }
  },
};

export default error;
