import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import DataForm from "@standard/components/DataForm/DataForm";
import { FormService, AlertService } from "@standard/services";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SettingTabs from "./SettingTabs";

export default function Setting({ }) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = "master/setting";

  const defaultValues = {
    _id: null,
    companyOwner: "",
    companyName: "",
    companyAddress: "",
    companyAddress2: "",
    companyZipCode: "",
    companyTelephone: "",
    companyEmail: "",
    companyTaxNo: "",
    policyPasswordAttempt: 5,
  };

  const [data, setData] = useState(defaultValues);
  const reactHookForm = useForm({ defaultValues });

  useEffect(() => {
    FormService.getRequest(url).then((dataResult) => {
      dataResult.policyPasswordAttempt = (dataResult.policyPasswordAttempt ?? "");
      setData({ ...defaultValues, ...dataResult });
      setIsLoading(false);
    });
  }, []);

  const saveData = () => {
    setIsLoading(true);

    const params = { ...data };

    FormService.submitRequestWithFile(url, params)
      .then((result) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      });
  };

  const onSubmit = () => {
    AlertService.confirm("Do you want to save data").then((res) => {
      if (res === true) saveData();
    });
  };

  const fields = [
    {
      name: "fields",
      type: "custom",
      control: (
        <SettingTabs
          key="fields"
          value={data}
          onChange={(newValue) => {
            setData((prevData) => {
              return { ...prevData, [newValue.name]: newValue.value };
            });
          }}
        />
      ),
    },
  ];

  const buttons = [];

  return (
    <BoxContainer loading={isLoading} errors={errors}>
      <DataForm
        refForm={reactHookForm}
        onSubmit={onSubmit}
        fields={fields}
        url={url}
        buttons={buttons}
      ></DataForm>
    </BoxContainer>
  );
}
