import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useState } from "react";
import DataForm, {
  DataFormContext,
} from "@standard/components/DataForm/DataForm";
import { AlertService, ErrorService } from "@standard/services";
import { AuthAPI } from "api";
import { useForm } from "react-hook-form";

export default function ChangePasswordWithAuth({ }) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const reactHookForm = useForm({ defaultValues });

  const onSubmit = (newData) => {
    if (isValidForm(newData) === false) return;
    AlertService.confirm("Do you want to change password ?").then((res) => {
      if (res === false) return;

      setIsLoading(true);

      const params = { ...newData };

      AuthAPI.changePassword(params)
        .then(() => {
          setIsLoading(false);
          AlertService.done("Password was changed.");
        })
        .catch((err) => {
          setIsLoading(false);
          if (typeof err === "string") setErrors([err]);
          else {
            console.error(err);
            setErrors(["Unexpected error, please contact admin"])
          }
        });
    });
  };

  const isValidForm = (data) => {
    const formErrors = [];
    if (data.newPassword !== data.confirmPassword) formErrors.push("Confirm Password must equal to New Password");

    if (formErrors.length === 0) return true;

    setErrors(formErrors);
    return false;
  };

  const fields = [
    {
      label: "Old Password",
      name: "oldPassword",
      type: "password",
      required: true,
    },
    {
      label: "New Password",
      name: "newPassword",
      type: "password",
      required: true,
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      type: "password",
      required: true,
    },
  ];

  const fieldErrors = ErrorService.convertToArray(
    reactHookForm.formState.errors
  );

  return (
    <BoxContainer loading={isLoading} errors={errors.concat(fieldErrors)}>
      <DataForm
        refForm={reactHookForm}
        fields={fields}
        onSubmit={onSubmit}
        onErrors={(errors) => setErrors(errors)}
      ></DataForm>
    </BoxContainer>
  );
}
