import DataTable from "@standard/components/DataTable";
import { DateService, NumberService } from "@standard/services";
import { OrderStatusComponent } from "data/orderStatus";
import { PaymentStatusComponent } from "data/paymentStatus";
import { getURLSearchCriteria } from "@standard/store/reducers/dataTableSlice";
import { useDispatch } from "react-redux";
import axios from "@standard/axios";
import { ServiceTypeComponent } from "data/serviceType";
import { OrderStatusData, PaymentStatusData, ServiceTypeData } from "data";

const columns = [
  {
    label: "Document No",
    name: "documentNo",
    align: "center",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Order Date",
    name: "orderDate",
    align: "center",
    sortable: true,
    search: { type: "date", isRange: true },
  },
  {
    label: "Service",
    name: "serviceType",
    align: "center",
    sortable: true,
    search: { type: "checkbox", inline: true, datasource: ServiceTypeData },
  },
  {
    label: "Payment Status",
    name: "paymentStatus",
    align: "center",
    sortable: true,
    search: { type: "checkbox", inline: true, datasource: PaymentStatusData },
  },
  {
    label: "Status",
    name: "status",
    align: "center",
    sortable: true,
    search: { type: "checkbox", inline: true, datasource: OrderStatusData },
  },
  {
    label: "Name",
    name: "name",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Telephone",
    name: "telephone",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Pickup",
    name: "startDestination",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Dropoff",
    name: "endDestination",
    align: "left",
    sortable: true,
    search: { type: "text" },
  },
  {
    label: "Net",
    name: "net",
    align: "right",
    sortable: true,
  },
];

const config = {

  customFilters: [
    {
      label: "Net From",
      name: "netFrom",
      type: "number",
    },
    {
      label: "Net To",
      name: "netTo",
      type: "number",
    },
  ],
  enableActive: false,
  enableEdit: true,
  enableInsert: false,
  enableTrash: false,
  enableView: true,
  onBeforeBind: (data) => {
    return data.map((item) => {
      const { paymentStatus, status, orderDate, net, startDestination, endDestination, name, serviceType } = item.values;

      item.values.paymentStatus = (
        <PaymentStatusComponent value={paymentStatus} />
      );

      item.values.startDestination = startDestination.length > 50 ? startDestination.substring(0, 50) + "..." : startDestination;
      item.values.endDestination = endDestination.length > 50 ? endDestination.substring(0, 50) + "..." : endDestination;
      item.values.name = name.length > 50 ? name.substring(0, 50) + "..." : name;

      item.values.status = <OrderStatusComponent value={status} />;
      item.values.serviceType = <ServiceTypeComponent value={serviceType} />

      item.values.orderDate = DateService.convertDateTimeToString(orderDate);
      item.values.net = NumberService.convertToMoney(net);

      return item;
    });
  },
};

export default ({ url }) => {
  const dispatch = useDispatch();

  const buttons = [
    {
      variant: "danger",
      label: '<i className="fa fa-print" aria-hidden="true"></i> Export Excel',
      onClick: () => {
        const printUrl = `${axios.defaults.baseURL}${url}/export`;
        const printUrlWithParam = dispatch(getURLSearchCriteria(printUrl));

        window.open(printUrlWithParam, "_blank");
      },
    },
  ];

  config.buttons = buttons;


  return <DataTable columns={columns} url={url} {...config} />;
};
