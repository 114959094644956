import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import { DatasourceAPI, OrderAPI } from "api";
import {
  AlertService,
  DateService,
  ErrorService,
  FormService,
  NumberService,
} from "@standard/services";
import { useForm } from "react-hook-form";
import { PaymentStatusComponent } from "data/paymentStatus";
import { ORDER_STATUS, OrderStatusComponent } from "data/orderStatus";
import OrderFormGeneral from "./OrderFormGeneral";
import OrderFormCustomerInfo from "./OrderFormCustomerInfo";
import OrderFormVehicle from "./OrderFormVehicle";
import OrderFormPayment from "./OrderFormPayment";
import OrderFormPlace from "./OrderFormPlace";
import Modal from "@standard/components/Modal";
import AlertBar from "@standard/components/AlertBar";
import { ServiceTypeComponent } from '../../../data/serviceType';
import { SubserviceTypeComponent } from 'data/subserviceType';

export default ({ history, url }) => {
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(action === "view");
  const [isLoading, setIsLoading] = useState(true);

  const defaultValues = {
    id: id,
    documentNo: "",
    orderDate: "",
    paymentStatus: "",
    status: "",
    memberName: "",
    memberTelephone: "",
    net: "",
    total: "",
    couponCode: "",
    discount: "",
  };

  const reactHookForm = useForm({ defaultValues });

  useEffect(() => {
    if (id !== undefined) {
      retrieveData(id);
    }
  }, []);

  const retrieveData = (id) => {
    FormService.getRequest(url, id).then((result) => {

      result.paymentStatus = (
        <PaymentStatusComponent value={result.paymentStatus} />
      );
      setDisabled(result.status !== ORDER_STATUS.WAITING)
      result.status = <OrderStatusComponent value={result.status} />;
      result.orderDate = DateService.convertDateTimeToString(
        result.orderDate
      );

      result.net = NumberService.convertToMoney(result.net);
      result.total = NumberService.convertToMoney(result.total);
      result.discount = result.discount ? NumberService.convertToMoney(result.discount) : "";

      reactHookForm.reset(result);
      setIsLoading(false);
    });
  };

  const fields = [
    {
      type: "custom",
      control: <OrderFormGeneral key="general" {...reactHookForm.getValues()} />
    },
    {
      type: "custom",
      control: <OrderFormPlace key="place" {...reactHookForm.getValues()} />
    },
    {
      type: "custom",
      control: <OrderFormCustomerInfo key="customer" {...reactHookForm.getValues()} />
    },
    {
      type: "custom",
      control: <OrderFormVehicle key="vehicle" {...reactHookForm.getValues()} />
    },
    {
      type: "custom",
      control: <OrderFormPayment key="payment" {...reactHookForm.getValues()} />
    }
  ];

  const fieldErrors = ErrorService.convertToArray(
    reactHookForm.formState.errors
  );

  let buttons = [];

  const leftPanel = <>
    <div><ServiceTypeComponent value={reactHookForm.getValues("serviceType")} /></div>
    <div><SubserviceTypeComponent value={reactHookForm.getValues("subserviceType")} /></div>
  </>

  if (disabled === false) {
    buttons = [
      <Modal
        key="done"
        headerClass="bg-primary"
        label={<><i className="fa fa-check"></i> Make it done</>}
        title="Done"
        enableSave={true}
        onSave={(values) => {
          return new Promise(resolve => {
            AlertService.confirm("Do you want to complete this transaction").then(res => {
              if (res === true) OrderAPI.completeOrder(id, values.remark).then(() => AlertService.done("Done")).then(() => history.push(`/${url}`))
            })
          });
        }
        }
        fields={
          [
            {
              name: "remark", type: "textarea", label: "Remark"
            }
          ]}
        disabled={disabled}
        variant="primary"
      />,
      <Modal
        key="cancel"
        btnClass="mx-2"
        headerClass="bg-danger"
        label={<><i className="fa fa-times"></i> Cancel</>}
        title="Cancel"
        enableSave={true}
        onSave={(values) => {
          return new Promise(resolve => {
            AlertService.confirm("Do you want to cancel this transaction").then(res => { if (res === true) OrderAPI.cancelOrder(id, values.remark).then(() => AlertService.done("Done")) }).then(() => history.push(`/${url}`))
          });
        }}
        fields={
          [
            {
              name: "remark", type: "textarea", label: "Remark"
            }
          ]}
        disabled={disabled}
        variant="danger"
      />
    ];
  }

  return (
    <BoxContainer loading={isLoading} errors={errors.concat(fieldErrors)}><>
      {reactHookForm.getValues("remark") && <AlertBar remark={reactHookForm.getValues("remark")} />}
      <DataForm
        leftPanel={leftPanel}
        refForm={reactHookForm}
        fields={fields}
        buttons={buttons}
        url={url}
        enableSave={false}
      ></DataForm>
    </>
    </BoxContainer>
  );
};
