export const ORDER_STATUS = {
  WAITING: "waiting",
  DONE: "done",
  CANCEL: "cancel",
};

const data = [
  { key: ORDER_STATUS.WAITING, label: "WAITING" },
  { key: ORDER_STATUS.DONE, label: "DONE" },
  { key: ORDER_STATUS.CANCEL, label: "CANCEL" },
];

export default data;

export const OrderStatusComponent = ({ value }) => {
  const message = data.find((f) => f.key === value)?.label ?? "";
  let badgeType = "";
  switch (value) {
    case ORDER_STATUS.WAITING:
      badgeType = "warning";
      break;
    case ORDER_STATUS.DONE:
      badgeType = "primary";
      break;
    case ORDER_STATUS.CANCEL:
      badgeType = "danger";
      break;
  }

  return <div className={`badge badge-${badgeType}`}>{message}</div>;
};
