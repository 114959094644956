import { forwardRef } from "react";
import { AlertService, UtilsService } from "@standard/services";

const FormInputFilePreview = forwardRef(
  ({ field, fieldValue = [], onChange }, ref) => {
    const onFileDelete = (id, fileName) => {
      AlertService.confirm(`Do you want to delete ${fileName} ?`).then(
        (res) => {
          if (res === true) {
            // delete files
            const newValue = fieldValue.map((value) => {
              if (value._id === id) {
                value.isDelete = true;
              }

              return value;
            });
            onChange({ name: field.name, value: newValue });
          }
        }
      );
    };

    const onFileRollback = (id, fileName) => {
      AlertService.confirm(`Do you want to undo ${fileName}`).then((res) => {
        if (res === true) {
          const newValue = fieldValue.map((value) => {
            if (value._id === id) {
              value.isDelete = false;
            }

            return value;
          });
          onChange({ name: field.name, value: newValue });
        }
      });
    };

    var previewFiles;
    if (fieldValue instanceof Array) {
      const child = fieldValue.map((item, index) => {
        var btnAction;
        let link;
        if (item.url)
          link = (
            <a href={item.url} target="_blank">
              {item.name}
            </a>
          );
        else link = <span>{item.name}</span>;

        if (field.disabled === false) {
          if (item.isDelete !== true) {
            btnAction = (
              <i
                onClick={(e) => {
                  onFileDelete(item._id, item.name);
                }}
                className="fa fa-close text-danger btn btn-link"
              ></i>
            );
          } else {
            btnAction = (
              <i
                onClick={(e) => {
                  onFileRollback(item._id, item.name);
                }}
                className="fa fa-undo text-primary btn btn-link"
              ></i>
            );
          }
        }

        if (item.isDelete) {
          link = <del>{link}</del>;
        }

        return (
          <li key={index}>
            <i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;
            {link}
            &nbsp;
            {btnAction}
          </li>
        );
      });

      previewFiles = <ul className="list-unstyled">{child}</ul>;
    } else {
      let link = (
        <a href={fieldValue.url} target="_blank">
          {fieldValue.name}
        </a>
      );

      if (fieldValue.isDelete) {
        link = <del>{link}</del>;
      }

      previewFiles = (
        <>
          <i className="fa fa-paperclip" aria-hidden="true"></i>&nbsp;
          {link}
        </>
      );
    }

    return (
      <div className="col-12" style={{ marginTop: "-5px" }}>
        {previewFiles}
      </div>
    );
  }
);

export default FormInputFilePreview;
