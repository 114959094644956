const getYear = (date) => date.getFullYear();
const getMonth = (date) => date.getMonth();
const range = (start, end, increment) => {
  let no = [];
  for (let i = start; i <= end; i += increment) no.push(i);

  return no;
};

const years = range(getYear(new Date()) - 100, getYear(new Date()) + 50, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DatePickerCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <input
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        value="<"
      />
      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <input
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        value=">"
      />
    </div>
  );
};

export default DatePickerCustomHeader;
