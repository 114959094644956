import BoxContainer from "@standard/components/BoxContainer/BoxContainer";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import DataForm from "@standard/components/DataForm/DataForm";
import {
  ErrorService,
  FormService,
} from "@standard/services";
import { useForm } from "react-hook-form";
import AlertBar from "@standard/components/AlertBar";
import { LinkButton } from "@standard/components/Button";

export default ({ history, url }) => {
  const { id, action } = useParams();
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const defaultValues = {
    refno: "",
    orderno: "",
    merchantid: "",
    customeremail: "",
    productdetail: "",
    cardtype: "",
    total: "",
    status: "",
    statusname: "",
    orderId: "",
    orderDocumentNo: "",
    errorMessage: "",
  };

  const reactHookForm = useForm({ defaultValues });

  useEffect(() => {
    if (id !== undefined) {
      retrieveData(id);
    }
  }, []);

  const retrieveData = (id) => {
    FormService.getRequest(url, id).then((result) => {
      result.orderDocumentNo = result.orderId ? <LinkButton label={result.orderDocumentNo} url={`/transaction/order/view/${result.orderId}`} /> : "-"
      reactHookForm.reset(result);
      setIsLoading(false);
    });
  };

  const fields = [
    { name: "refno", label: "refno", type: "label" },
    { name: "orderno", label: "orderno", type: "label" },
    { name: "merchantid", label: "merchantid", type: "label" },
    { name: "customeremail", label: "customeremail", type: "label" },
    { name: "productdetail", label: "productdetail", type: "label" },
    { name: "cardtype", label: "cardtype", type: "label" },
    { name: "total", label: "total", type: "label" },
    { name: "status", label: "status", type: "label" },
    { name: "statusname", label: "statusname", type: "label" },
    { name: "errorMessage", label: "Error Message", type: "label" },
  ];

  const fieldErrors = ErrorService.convertToArray(
    reactHookForm.formState.errors
  );

  let buttons = [];


  return (
    <BoxContainer loading={isLoading} errors={errors.concat(fieldErrors)}><>
      <div className="alert alert-primary"><strong>Order Document No:</strong>{reactHookForm.getValues("orderDocumentNo")}</div>
      <DataForm
        refForm={reactHookForm}
        fields={fields}
        buttons={buttons}
        url={url}
        enableSave={false}
      ></DataForm>
    </>
    </BoxContainer>
  );
};
