import { AlertService, UtilsService } from "@standard/services";
import {
  removeData,
  fetchData,
  setSelectedId,
  setActive,
} from "@standard/store/reducers/dataTableSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, ButtonGroup, Button } from "react-bootstrap";
import Switcher from "../Switcher/Switcher";

const onDelete = (id, dispatch) => {
  AlertService.confirm("Do you want to delete data ?").then((willDelete) => {
    if (willDelete) {
      dispatch(removeData(id));
    }
  });
};

function SearchResultData() {
  const path = window.location.pathname;

  const dispatch = useDispatch();
  const { data, isLoading, searchCriteria, config, selectedId } = useSelector(
    (state) => state.dataTable
  );

  const {
    columns,
    enableCheckbox,
    enableActive,
    enableAction,
    enablePrint,
    enableEdit,
    enableTrash,
    enableView,
    buttonsRow,
    onButtonRowRender,
    onPrint,
    buttonEditLabel,
  } = config;

  // if (isLoading === true) {
  //   return (
  //     <tr>
  //       <td
  //         colSpan={
  //           columns.length + 1 + (enableAction ? 1 : 0) + (enableActive ? 1 : 0)
  //         }
  //         className="text-center"
  //       >
  //         Loading ...
  //       </td>
  //     </tr>
  //   );
  // }

  const { recordPerPage, pageNumber } = searchCriteria;

  var running = recordPerPage * pageNumber - recordPerPage + 1;
  const rowData = data.map((item, itemIndex) => {
    const key = item.key;

    const colData = columns.map((col, subItemIndex) => {
      let alignClass = "text-left";
      const key = col.name;

      const subItem = item.values[key] ?? "";
      let reactElement;
      if (col.align === "center") {
        alignClass = "text-center";
      } else if (col.align === "right") {
        alignClass = "text-right";
      }

      if (UtilsService.isReactElement(subItem) === true) {
        reactElement = subItem;
      } else {
        reactElement = UtilsService.htmlParse(subItem);
      }

      return (
        <td className={alignClass} key={subItemIndex}>
          {reactElement}
        </td>
      );
    });

    let colDataCheckbox;

    if (enableCheckbox === true) {
      let checked = selectedId.indexOf(key) >= 0;
      let disabledItem = item.selectable === false;

      colDataCheckbox = (
        <td key={-3} className="text-center">
          <Form.Check
            inline
            disabled={disabledItem}
            type="checkbox"
            id={`selected_${key}`}
            checked={checked}
            onChange={(e) => dispatch(setSelectedId(key))}
          />
        </td>
      );
    } else {
      colDataCheckbox = (
        <td key={-3} className="text-center">
          {running++}
        </td>
      );
    }

    colData.unshift(colDataCheckbox);

    if (enableActive === true) {
      var colDataActive = (
        <td key={-2} className="text-center">
          <Switcher
            id="active"
            name="active"
            checked={item.active}
            onChange={(e) => {
              dispatch(setActive({ key: key, active: !item.active }));
            }}
          />
        </td>
      );
      colData.push(colDataActive);
    }

    if (enableAction === true) {
      let btnPrint;
      let btnTrash;
      let btnView;
      let btnEdit;

      if (enablePrint === true) {
        btnPrint = (
          <Button
            variant="warning"
            onClick={() => onPrint(item, itemIndex)}
            title="Print"
          >
            {" "}
            <i className="fa fa-print"></i> Print
          </Button>
        );
      }

      if (
        enableEdit === true &&
        // (item.selectable === undefined || item.editable === true)
        (item.editable === undefined || item.editable === true)
      ) {
        btnEdit = (
          <Link
            title="Edit"
            to={`${path}/edit/${key}`}
            className="btn btn-secondary btn-small"
            disabled={true}
          >
            {buttonEditLabel ?? (
              <>
                <i className="fa fa-pencil"></i> Edit
              </>
            )}
          </Link>
        );
      }

      if (
        enableTrash === true &&
        (item.removable === undefined || item.removable === true)
      ) {
        btnTrash = (
          <Button
            variant="danger"
            onClick={() => onDelete(key, dispatch)}
            title="Delete"
          >
            {" "}
            <i className="fa fa-trash"></i> Delete
          </Button>
        );
      }
      if (enableView === true) {
        btnView = (
          <Link
            title="View"
            to={`${path}/view/${key}`}
            className="btn btn-info btn-small"
          >
            <i className="fa fa-eye"></i> View
          </Link>
        );
      }

      const buttonsRowWithCondition = onButtonRowRender
        ? onButtonRowRender(item, itemIndex)
        : null;

      var colDataAction = (
        <td key={-1} className="text-center">
          <ButtonGroup>
            {btnPrint}
            {btnView}
            {btnEdit}
            {btnTrash}
            {buttonsRow.map((btnRow) => {
              return (
                <Button
                  variant={btnRow.variant}
                  key={btnRow.label}
                  onClick={() => btnRow.onClick(key, item.values)}
                  title={btnRow.label}
                >
                  {btnRow.label}
                </Button>
              );
            })}
            {buttonsRowWithCondition}
          </ButtonGroup>
        </td>
      );

      colData.push(colDataAction);
    }

    const classRow = [];

    if (item.active === false) {
      classRow.push("bg-warning text-dark");
    }

    return (
      <tr key={key} className={classRow.join(" ")}>
        {colData}
      </tr>
    );
  });

  return rowData;
}

export default SearchResultData;
