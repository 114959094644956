import { forwardRef } from "react";

const FormInputRadio = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { name, disabled, datasource, inline } = field;

  return (
    <>
      {datasource.map((item) => {
        const uniqueId = Math.random().toString(16).slice(2);
        const key = `${name}_${item.key}_${uniqueId}`;
        return (
          <div
            className={`form-check ${inline ? "form-check-inline" : ""}`}
            key={key}
          >
            <input
              disabled={disabled}
              className="form-check-input"
              type="radio"
              name={item.name}
              id={key}
              value={item.key}
              checked={fieldValue === item.key}
              onChange={(e) => {
                onChange({ name, value: e.target.value });
              }}
            />
            <label className="form-check-label" htmlFor={key}>
              {item.label}
            </label>
          </div>
        );
      })}
    </>
    // <input
    //   ref={ref}
    //   className="form-control"
    //   name={name}
    //   type={type}
    //   placeholder={label}
    //   value={fieldValue}
    //   disabled={disabled}
    //   onChange={(e) => onChange({ name, value: e.target.value })}
    // />
  );
});

export default FormInputRadio;
