import FormInput from "@standard/components/FormInput/FormInput";

export default function Policy({ value, onChange }) {
  const fields = [
    {
      name: "policyPasswordAttempt",
      label: "Password Attempt",
      type: "number",
    },
  ];

  return (
    <div>
      {fields.map((field) => (
        <FormInput
          field={field}
          key={field.name}
          value={value[field.name]}
          onChange={onChange}
        />
      ))}
    </div>
  );
}
