import { forwardRef } from "react";

const FormInputCheckbox = forwardRef(({ field, fieldValue, onChange }, ref) => {
  const { name, disabled, datasource = null, inline } = field;

  if (datasource === null) {
    return (
      <div className={`form-check form-check-inline`}>
        <input
          disabled={disabled}
          className="form-check-input"
          type="checkbox"
          name={name}
          id={name}
          value={fieldValue}
          checked={fieldValue === true}
          onChange={(e) => {
            onChange({
              name,
              value: e.target.checked,
            });
          }}
        />
      </div>
    );
  }

  return datasource.map((item) => {
    const uniqueId = Math.random().toString(16).slice(2);
    const key = `${name}_${item.key}_${uniqueId}`;
    const checked = fieldValue?.includes(item.key) ?? false;

    return (
      <div
        className={`form-check ${inline ? "form-check-inline" : ""}`}
        key={key}
      >
        <input
          disabled={disabled}
          className="form-check-input"
          type="checkbox"
          name={item.name}
          id={key}
          value={item.key}
          checked={checked}
          onChange={(e) => {
            if (e.target.checked === true) {
              onChange({
                name,
                value: [...(fieldValue ?? []), e.target.value],
              });
            } else {
              onChange({
                name,
                value: fieldValue.filter((f) => f !== e.target.value),
              });
            }
          }}
        />
        <label className="form-check-label" htmlFor={key}>
          {item.label}
        </label>
      </div>
    );
  });
});

export default FormInputCheckbox;
