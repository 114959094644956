import { useRef, useEffect } from "react";
import "./style.css";

const $ = window.$;

export const FILE_INPUT_EXTENSION = {
  IMAGE: "image",
  PDF: "pdf",
};

const FileInput = ({
  disabled,
  name,
  multiple,
  extension,
  value,
  onChange,
}) => {
  const element = useRef(null);

  const onFileChange = (e) => {
    const { files } = e.target;
    onChange({ name, value: files });
  };

  useEffect(() => {
    const options = {
      showPreview: false,
      showUpload: false,
    };

    if ($(element.current).length > 0) {
      $(element.current)
        .fileinput(options)
        .on("change", function (event) {
          console.log("onChange file", event.target);
        })
        .on("fileselect", (event, numFiles, label) => {
          onFileChange(event);
        })
        // .on("fileremoved", () => {
        //   console.log("fileremoved");
        // })
        // .on("filedeleted", () => {
        //   console.log("filedeleted");
        // })
        // .on("fileclear", () => {
        //   console.log("fileclear");
        // })
        .on("filecleared", (event) => {
          onFileChange(event);
        });
      // .on("filereset", () => {
      //   console.log("filereset");
      // });
    }
  }, []);

  let accept = "";

  if (extension === FILE_INPUT_EXTENSION.IMAGE) {
    accept = "image/x-png,image/gif,image/jpeg";
  } else if (extension === FILE_INPUT_EXTENSION.PDF) {
    accept = ".pdf";
  }

  const fileInputClass = [];

  if (disabled) {
    fileInputClass.push("d-none");
  }

  let input = (
    <input
      type="file"
      accept={accept}
      id={name}
      name={name}
      ref={element}
      multiple={multiple}
      disabled={disabled}
    />
  );

  return <div className={fileInputClass.join(" ")}>{input}</div>;
};

export default FileInput;
